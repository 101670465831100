import {
  TransportEventRequestBodyClassifierEnum,
  TransportEventClassifierEnum,
  TransportEventRequestBodySubTypeEnum,
  TransportEventSubTypeEnum,
  TransportEventTypeEnum,
  TransportEventRequestBodyTypeEnum,
} from '@beacon-devops/shipment-tracker-client';

export const mapTransportEventClassifierToTransportEventRequestBodyClassifier = (
  classifier: TransportEventClassifierEnum,
): TransportEventRequestBodyClassifierEnum => {
  const key = Object.keys(TransportEventClassifierEnum).find(
    (k) => TransportEventClassifierEnum[k as keyof typeof TransportEventClassifierEnum] === classifier,
  );
  const value = TransportEventRequestBodyClassifierEnum[key as keyof typeof TransportEventRequestBodyClassifierEnum];
  return value;
};

export const mapTransportEventSubTypeToTransportEventRequestBodySubType = (
  subType: TransportEventSubTypeEnum,
): TransportEventRequestBodySubTypeEnum => {
  const key = Object.keys(TransportEventSubTypeEnum).find(
    (k) => TransportEventSubTypeEnum[k as keyof typeof TransportEventSubTypeEnum] === subType,
  );
  const value = TransportEventRequestBodySubTypeEnum[key as keyof typeof TransportEventRequestBodySubTypeEnum];
  return value;
};

export const mapTransportEventTypeToTransportEventRequestBodyType = (
  type: TransportEventTypeEnum,
): TransportEventRequestBodyTypeEnum => {
  const key = Object.keys(TransportEventTypeEnum).find(
    (k) => TransportEventTypeEnum[k as keyof typeof TransportEventTypeEnum] === type,
  );
  const value = TransportEventRequestBodyTypeEnum[key as keyof typeof TransportEventRequestBodyTypeEnum];
  return value;
};
