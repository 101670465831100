import {
  CarrierShipment,
  CarrierShipmentModeEnum,
  CarrierShipmentModeOfTrackingEnum,
  CarrierShipmentRequestBody,
  CarrierShipmentRequestBodyModeEnum,
  CarrierShipmentRequestBodyModeOfTrackingEnum,
} from '@beacon-devops/shipment-tracker-client';

function carrierShipmentModeEnumToCarrierShipmentRequestBodyModeEnum(
  mode: CarrierShipmentModeEnum,
): CarrierShipmentRequestBodyModeEnum {
  const key = Object.keys(CarrierShipmentModeEnum).find(
    (k) => CarrierShipmentModeEnum[k as keyof typeof CarrierShipmentModeEnum] === mode,
  );
  const value = CarrierShipmentRequestBodyModeEnum[key as keyof typeof CarrierShipmentRequestBodyModeEnum];
  return value;
}

function carrierShipmentModeOfTrackingEnumToCarrierShipmentRequestBodyModeOfTrackingEnum(
  modeOfTracking: CarrierShipmentModeOfTrackingEnum,
): CarrierShipmentRequestBodyModeOfTrackingEnum {
  const key = Object.keys(CarrierShipmentModeOfTrackingEnum).find(
    (k) => CarrierShipmentModeOfTrackingEnum[k as keyof typeof CarrierShipmentModeOfTrackingEnum] === modeOfTracking,
  );
  const value =
    CarrierShipmentRequestBodyModeOfTrackingEnum[key as keyof typeof CarrierShipmentRequestBodyModeOfTrackingEnum];
  return value;
}

export const carrierShipmentToCarrierShipmentRequestBody = (
  carrierShipment: CarrierShipment,
): CarrierShipmentRequestBody => {
  return {
    ...carrierShipment,
    mode: carrierShipment.mode
      ? carrierShipmentModeEnumToCarrierShipmentRequestBodyModeEnum(carrierShipment.mode)
      : undefined,
    modeOfTracking: carrierShipment.modeOfTracking
      ? carrierShipmentModeOfTrackingEnumToCarrierShipmentRequestBodyModeOfTrackingEnum(carrierShipment.modeOfTracking)
      : undefined,
  };
};
