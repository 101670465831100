import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { useCargoControllerApi } from '@services/ShipmentTrackerService/cargoControllerApi/useCargoControllerApi';
import { useTransportCallApi } from '@services/ShipmentTrackerService/transportCallApi/useTransportCallApi';
import { notification } from 'antd';
import { useState } from 'react';

interface UpdatePortHookResult {
  isSaving: boolean;
  updatePortInTransportCalls: (loadTransportCallId: string, locationId: string) => Promise<void>;
}

/**
 * Hook to update the port in transport calls.
 * The port is first searched by the port name.
 * If the port is not found, it is created.
 * Then the port is updated in the transport calls.
 */
export function useUpdatePort(): UpdatePortHookResult {
  const [isSaving, setIsSaving] = useState(false);
  const { updateLocationInTransportCall } = useTransportCallApi();
  const { shipmentTrackerCargo, refetchAllData } = useShipmentDetailPageCargoData();
  const { reindexCargoByCargoId } = useCargoControllerApi();
  const cargoId = shipmentTrackerCargo?.id || '';

  return {
    isSaving,
    async updatePortInTransportCalls(loadTransportCallId: string, locationId: string) {
      if (loadTransportCallId) {
        setIsSaving(true);

        if (locationId) {
          await updateLocationInTransportCall(loadTransportCallId, locationId);
        }

        notification.success({
          message: 'Port updated successfully',
          placement: 'bottomRight',
        });

        await reindexCargoByCargoId(cargoId);
        setTimeout(() => {
          refetchAllData();
          setIsSaving(false);
          document.querySelectorAll('.ant-select-focused').forEach((picker) => {
            picker.classList.remove('ant-select-focused');
          });
        }, 300);
      }
    },
  };
}
