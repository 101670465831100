import React, { FC } from 'react';
import { EquipmentKind } from '@beacon-devops/container-tracking-api-model-ts-client';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { mapEquipmentISOCodeToKind } from '@features/shipmentDetail/utils/common';
import { CDetailsSelect } from '../../pages/OceanContainer/styles';
import { FormLabel } from '../form';
import { useUpdateEquipmentKind } from './useUpdateEquipmentKind';

const EquipmentKindInput: FC = () => {
  const { updateEquipmentKind, isSaving } = useUpdateEquipmentKind();
  const { shipmentTrackerCargo } = useShipmentDetailPageCargoData();

  return (
    <>
      <FormLabel htmlFor='equipmentKind'>Equipment Kind</FormLabel>

      <CDetailsSelect
        value={mapEquipmentISOCodeToKind(shipmentTrackerCargo?.container?.isoCode || '')}
        showSearch
        loading={isSaving}
        disabled={isSaving}
        onChange={async (value) => {
          const isoCode = (value as string).split('_').pop();
          await updateEquipmentKind(isoCode as string);
        }}
        options={Object.values(EquipmentKind)
          .sort()
          .map((ek) => ({
            value: ek,
            label: ek.replace('EQUIPMENT_KIND', '').replace(/_/g, ' '),
          }))}
      />
    </>
  );
};

export default EquipmentKindInput;
