import React, { FC } from 'react';
import { Box } from '@beacon-devops/components';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  TransportEventSubTypeEnum,
  CargoEventTypeEnum,
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { ContainerFromToDisplay } from '../ContainerFromToDisplay';
import { Vessel } from '../Vessel/Vessel';
import { SCVContainerFormValues } from '../../types';
import {
  BerthedPlacement,
  CargoLoadedPlacement,
  CargoOffLoadedPlacement,
  CDetailsCard,
  CDetailsCardContent2RowsLayout,
  FirstVesselImoPlacement,
  FromToPlacement,
} from '../../pages/OceanContainer/styles';
import { ShipmentDate } from '../ShipmentDate';
import { useShipmentDetailPageCargoData } from '../../hooks/useShipmentDetailPageData';
import { VoyageOrAirlineNumber } from '../VoyageOrAirlineNumber/VoyageOrAirlineNumber';

interface PortOfLoadingTransportSectionProps {
  mode?: Mode;
}

export const PortOfLoadingTransportSection: FC<PortOfLoadingTransportSectionProps> = ({ mode = Mode.Ocean }) => {
  const { control } = useFormContext<SCVContainerFormValues>();
  const { orderedTransportCallIds } = useShipmentDetailPageCargoData();
  const loadTransportCallId = orderedTransportCallIds[0].loadTransportCallId || '';
  const dischargeTransportCallId = orderedTransportCallIds[0].dischargeTransportCallId || '';

  const isOcean = mode === Mode.Ocean;
  const isAir = mode === Mode.Air;

  const firstTranshipment = useWatch({
    control,
    name: 'transhipments.0',
  });

  const portOfLoading = useWatch({
    control,
    name: 'departurePort',
  });

  const portOfDischarge = useWatch({
    control,
    name: 'arrivalPort',
  });

  const port1 = isAir ? 'Transhipment Airport 1' : 'Transhipment Seaport 1';

  const handlePortOfDischarge = () => {
    if (firstTranshipment) {
      return firstTranshipment?.port?.name || port1;
    }

    return portOfDischarge?.name || '';
  };

  return (
    <CDetailsCard>
      <CDetailsCardContent2RowsLayout>
        <FromToPlacement>
          <ContainerFromToDisplay from={portOfLoading?.name} to={handlePortOfDischarge()} />
        </FromToPlacement>

        <Box>
          <VoyageOrAirlineNumber transportCallId={loadTransportCallId} mode={mode} />
        </Box>

        {isOcean && (
          <FirstVesselImoPlacement>
            <Vessel
              label='Vessel'
              loadTransportCallId={loadTransportCallId}
              dischargeTransportCallId={dischargeTransportCallId}
            />
          </FirstVesselImoPlacement>
        )}

        {isAir && (
          <CargoLoadedPlacement>
            <ShipmentDate
              label='Cargo Loaded'
              name='loadTransport.loaded.actualDate'
              eventClassifier={CargoEventClassifierEnum.Actual}
              eventSubType={CargoEventSubTypeEnum.Load}
              eventType={CargoEventTypeEnum.Cargo}
              transportCallId={loadTransportCallId}
            />
          </CargoLoadedPlacement>
        )}

        <ShipmentDate
          label='Estimated Departure'
          name='loadTransport.departure.expectedDate'
          eventClassifier={CargoEventClassifierEnum.Estimated}
          eventSubType={TransportEventSubTypeEnum.Depart}
          eventType={CargoEventTypeEnum.Transport}
          transportCallId={loadTransportCallId}
        />

        <ShipmentDate
          label='Estimated Arrival'
          name='loadTransport.arrival.expectedDate'
          eventClassifier={CargoEventClassifierEnum.Estimated}
          eventSubType={TransportEventSubTypeEnum.Arrive}
          eventType={CargoEventTypeEnum.Transport}
          transportCallId={dischargeTransportCallId}
        />

        <ShipmentDate
          label='Actual Departure'
          name='loadTransport.departure.actualDate'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={TransportEventSubTypeEnum.Depart}
          eventType={CargoEventTypeEnum.Transport}
          transportCallId={loadTransportCallId}
        />

        <ShipmentDate
          label='Actual Arrival'
          name='loadTransport.arrival.actualDate'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={TransportEventSubTypeEnum.Arrive}
          eventType={CargoEventTypeEnum.Transport}
          transportCallId={dischargeTransportCallId}
        />

        {isAir && (
          <CargoOffLoadedPlacement>
            <ShipmentDate
              label='Cargo Off-Loaded'
              name='loadTransport.discharged.actualDate'
              eventClassifier={CargoEventClassifierEnum.Actual}
              eventSubType={CargoEventSubTypeEnum.Discharge}
              eventType={CargoEventTypeEnum.Cargo}
              transportCallId={dischargeTransportCallId}
            />
          </CargoOffLoadedPlacement>
        )}
        {!firstTranshipment && isOcean && (
          <BerthedPlacement>
            <ShipmentDate
              label='Actual Berthed'
              name='berth.actualDate'
              eventClassifier={CargoEventClassifierEnum.Actual}
              eventSubType={TransportEventSubTypeEnum.Berth}
              eventType={CargoEventTypeEnum.Transport}
              transportCallId={dischargeTransportCallId}
            />
          </BerthedPlacement>
        )}
      </CDetailsCardContent2RowsLayout>
    </CDetailsCard>
  );
};
