import { CustomerStatus } from '@beacon-devops/customer-service-client';
import CustomerService from '@services/CustomerService';
import { useQueries, useQuery } from 'react-query';

export const useGetCustomersQuery = ({
  activeOnly,
  isTest,
  isFreeTracking,
}: {
  activeOnly?: boolean;
  isTest?: boolean;
  isFreeTracking?: boolean;
}) =>
  useQuery('customers', async () => {
    const results = await CustomerService.getAll(isTest, isFreeTracking);
    return activeOnly ? results.filter((x) => x.status === CustomerStatus.Active) : results;
  });

export const useCustomerByIds = (ids: string[]) =>
  useQueries(
    ids.map((id) => ({
      queryKey: ['customer', id],
      queryFn: () => CustomerService.getCustomer(id),
      staleTime: 60 * 1000,
    })),
  );

export const useCustomer = (id: string, enabled = true) =>
  useQuery(
    ['customer', id],
    () => {
      return CustomerService.getCustomer(id);
    },
    {
      staleTime: 30 * 1000,
      enabled,
    },
  );

export const useAllCustomerCapabilityDefinitions = () =>
  useQuery(
    ['customer-capability-definitions'],
    () => {
      return CustomerService.getCustomerCapabilityDefinitions();
    },
    {
      staleTime: 30 * 1000,
    },
  );
