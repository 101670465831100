import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { TransportEvent, EventDateTime, Location } from '@beacon-devops/shipment-tracker-client';
// Local imports
import { getLocationById } from '@services/ShipmentTrackerService/ShipmentTrackerService';
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import {
  AddInspectionEntity,
  buildInspectLink,
  buildLocationLink,
  renderEntityItem,
  renderEntityItemDateTime,
  ScvEntityType,
} from './ScvCommanderEntityUtils';

interface ScvCommanderEntityCargoEventProps {
  entityId: string;
  entityData: TransportEvent;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Transport Event given some data from Shipment Tracker.
 */
export const ScvCommanderEntityTransportEvent = (props: ScvCommanderEntityCargoEventProps): JSX.Element => {
  const transportEvent = props.entityData;

  const [eventLocation, setEventLocation] = useState<Location>();

  // Fetch the location related to the event
  useEffect(() => {
    if (transportEvent.locationId) {
      getLocationById(transportEvent.locationId).then((location) => setEventLocation(location));
    }
  }, [transportEvent.locationId]);

  /**
   * Renders the Transport Event card plus any extra information for this entity
   */
  function render(transportEvent: TransportEvent): JSX.Element {
    function renderEventDateTime(eventDateTime: EventDateTime): string {
      return eventDateTime ? `${eventDateTime.timestamp} (${eventDateTime.zone})` : '';
    }

    return (
      <>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Transport Event</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', transportEvent.createdAt)}
          {renderEntityItemDateTime('Last Updated At', transportEvent.updatedAt)}
          {renderEntityItem('Type', transportEvent.subType)}
          {renderEntityItem('Classifier', transportEvent.classifier)}
          {renderEntityItem('Source Type', transportEvent.source?.sourceType)}
          {renderEntityItem('Source Identifier', transportEvent.source?.identifier)}
          {renderEntityItem('Date & Time', renderEventDateTime(transportEvent.eventDateTime))}
          {renderEntityItem(
            'Transport Call',
            buildInspectLink(
              ScvEntityType.transportCall,
              transportEvent.transportCallId,
              props.addInspectionEntityFunc,
              true,
            ),
          )}
          {renderEntityItem(
            'Location',
            buildLocationLink(eventLocation, transportEvent.locationId, props.addInspectionEntityFunc),
          )}
        </Card>
      </>
    );
  }

  return <div>{transportEvent ? render(transportEvent) : '...loading'}</div>;
};
