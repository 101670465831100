import {
  CargoEventClassifierEnum,
  CargoEventRequestBodyClassifierEnum,
  CargoEventRequestBodySubTypeEnum,
  CargoEventRequestBodyTypeEnum,
  CargoEventSubTypeEnum,
  CargoEventTypeEnum,
} from '@beacon-devops/shipment-tracker-client';

export const mapCargoEventClassifierToCargoEventRequestBodyClassifier = (
  classifier: CargoEventClassifierEnum,
): CargoEventRequestBodyClassifierEnum => {
  const key = Object.keys(CargoEventClassifierEnum).find(
    (k) => CargoEventClassifierEnum[k as keyof typeof CargoEventClassifierEnum] === classifier,
  );
  const value = CargoEventRequestBodyClassifierEnum[key as keyof typeof CargoEventRequestBodyClassifierEnum];
  return value;
};

export const mapCargoEventSubTypeToCargoEventRequestBodySubType = (
  subType: CargoEventSubTypeEnum,
): CargoEventRequestBodySubTypeEnum => {
  const key = Object.keys(CargoEventSubTypeEnum).find(
    (k) => CargoEventSubTypeEnum[k as keyof typeof CargoEventSubTypeEnum] === subType,
  );
  const value = CargoEventRequestBodySubTypeEnum[key as keyof typeof CargoEventRequestBodySubTypeEnum];
  return value;
};

export const mapCargoEventTypeToCargoEventRequestBodyType = (
  type: CargoEventTypeEnum,
): CargoEventRequestBodyTypeEnum => {
  const key = Object.keys(CargoEventTypeEnum).find(
    (k) => CargoEventTypeEnum[k as keyof typeof CargoEventTypeEnum] === type,
  );
  const value = CargoEventRequestBodyTypeEnum[key as keyof typeof CargoEventRequestBodyTypeEnum];
  return value;
};
