import { Configuration, TransportCallEntityControllerApi } from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { shipmentTrackerBasePath } from '../constants';

export function useTransportCallApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const transportCallApi = new TransportCallEntityControllerApi(apiConfiguration);

  const updateVehicleInTransportCall = async (transportCallId: string, vehicleId: string) =>
    transportCallApi
      .patchItemResourceTransportcallPatch(transportCallId, { vehicleId })
      .then((entityModelPayload) => {
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error updating vehicle in transport call',
          description: `Error: ${error.message} ${error?.response?.data?.error}.`,
          placement: 'bottomRight',
          duration: 7,
        });
      });

  const updateLocationInTransportCall = async (transportCallId: string, locationId: string) =>
    transportCallApi
      .patchItemResourceTransportcallPatch(transportCallId, { locationId })
      .then((entityModelPayload) => {
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error updating location in transport call',
          description: `Error: ${error.message} ${error?.response?.data?.error}.`,
          placement: 'bottomRight',
          duration: 7,
        });
      });

  return {
    updateVehicleInTransportCall,
    updateLocationInTransportCall,
  };
}
