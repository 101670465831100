import React from 'react';
import { Switch, BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { PUBLIC_URL } from '@constants/index';
import ProtectedRoute from '@components/common/ProtectedRoute';
import { CustomersPage } from '@pages/Customers';
import UsersPage from '@pages/Users';
import UserPage from '@pages/User';
import UserUpdatePage from '@pages/UserUpdate';
import UserInvitePage from '@pages/UserInvite';
import UserInviteToCompanyPage from '@pages/UserInviteToCompany';
import LogoutPage from '@pages/Logout';
import CustomerPage from '@pages/SingleCustomer/index';
import { basePathOcean } from '@features/shipmentList/routes/paths';
import { ShipmentListRoutes } from '@features/shipmentList/routes';
import {
  CUSTOMERS_ROUTE,
  CUSTOMER_DETAIL_ROUTE,
  CUSTOMERS_CREATE_ROUTE,
  USERS_ROUTE,
  USER_DETAIL_ROUTE,
  USER_UPDATE_ROUTE,
  USER_INVITE_ROUTE,
  USER_INVITE_TO_COMPANY_ROUTE,
  LOGOUT,
  PLATFORM_ROUTE,
} from './constants';

const Routes = () => (
  <Router basename={PUBLIC_URL || '/'}>
    <Switch>
      <Redirect path='/' exact to={basePathOcean} />
      <ProtectedRoute path={PLATFORM_ROUTE} component={ShipmentListRoutes} />
      <ProtectedRoute path={CUSTOMERS_CREATE_ROUTE} component={CustomerPage} />
      <ProtectedRoute path={CUSTOMER_DETAIL_ROUTE} component={CustomerPage} />
      <ProtectedRoute path={CUSTOMERS_ROUTE} component={CustomersPage} />
      <ProtectedRoute exact path={USERS_ROUTE} component={UsersPage} />
      <ProtectedRoute exact path={USER_INVITE_TO_COMPANY_ROUTE} component={UserInviteToCompanyPage} />
      <ProtectedRoute exact path={USER_DETAIL_ROUTE} component={UserPage} />
      <ProtectedRoute exact path={USER_UPDATE_ROUTE} component={UserUpdatePage} />
      <ProtectedRoute exact path={USER_INVITE_ROUTE} component={UserInvitePage} />
      <Route exact path={LOGOUT} component={LogoutPage} />
    </Switch>
  </Router>
);

export default Routes;
