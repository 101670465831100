import { useState } from 'react';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import {
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  CargoEventTypeEnum,
  TransportEventClassifierEnum,
  TransportEventSubTypeEnum,
  TransportEventTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { mapLatestCargoOrTransportEventDateTime, getLocationZone } from '@features/shipmentDetail/utils/common';
import { useCargoEventApi } from '@services/ShipmentTrackerService/cargoEventApi/useCargoEventApi';
import { useCargoControllerApi } from '@services/ShipmentTrackerService/cargoControllerApi/useCargoControllerApi';
import { useTransportEventApi } from '@services/ShipmentTrackerService/transportEventApi/useTransportEventApi';
import moment, { Moment } from 'moment';

type BaseArgs = {
  eventClassifier: CargoEventClassifierEnum;
  transportCallId?: string | null;
};

type CargoEventArgs = BaseArgs & {
  eventSubType: CargoEventSubTypeEnum;
  eventType: CargoEventTypeEnum.Cargo;
};

type TransportEventArgs = BaseArgs & {
  eventSubType: TransportEventSubTypeEnum;
  eventType: CargoEventTypeEnum.Transport;
};

export type UpdateShipmentDateArgs = CargoEventArgs | TransportEventArgs;

export function useUpdateShipmentDate({
  eventClassifier,
  eventSubType,
  eventType,
  transportCallId,
}: UpdateShipmentDateArgs) {
  const {
    shipmentTrackerCargo,
    carrierShipmentData,
    cargoEvents,
    carrierShipmentTransportEvents,
    carrierShipmentTransportCalls,
    refetchAllData,
  } = useShipmentDetailPageCargoData();

  const isHandlingCargoEvent = eventType === CargoEventTypeEnum.Cargo;
  const isHandlingTransportEvent = eventType === CargoEventTypeEnum.Transport;

  const [isSaving, setIsSaving] = useState(false);
  const events = isHandlingCargoEvent ? cargoEvents : carrierShipmentTransportEvents;
  const transportCall = carrierShipmentTransportCalls.find((t) => t.id === transportCallId);
  const transportCallLocation = carrierShipmentData?.locations?.find((l) => l.id === transportCall?.locationId);
  const transportCallLocationId = transportCallLocation?.id || '';
  const transportCallLocationZone = getLocationZone(transportCallLocationId);
  const transportCallIdToSave = transportCallId || '';
  const eventDateTime = mapLatestCargoOrTransportEventDateTime(
    events,
    eventClassifier,
    eventSubType,
    transportCallLocationZone,
    isHandlingTransportEvent ? transportCallIdToSave : undefined,
  );
  const originalEventDate = eventDateTime?.timestamp ? moment(eventDateTime.timestamp) : undefined;
  const { createNewCargoEvent, deleteRelatedEventsBySubtype } = useCargoEventApi();
  const { createNewTransportEvent, saveAllTransportEvents } = useTransportEventApi();
  const { reindexCargoByCargoId } = useCargoControllerApi();
  const cargoId = shipmentTrackerCargo?.id || '';

  const onChangeDate = async (value: Moment | null) => {
    setIsSaving(true);
    const transportCallLocationTimestamp = value;

    if (eventType === CargoEventTypeEnum.Cargo) {
      if (transportCallLocationTimestamp) {
        await createNewCargoEvent({
          cargoId,
          eventSubType,
          eventType,
          eventClassifier,
          transportCallLocationId,
          transportCallId: transportCallIdToSave,
          transportCallLocationZone,
          transportCallLocationTimestamp,
        });
      } else {
        await deleteRelatedEventsBySubtype(cargoId, eventSubType, transportCallIdToSave);
      }
    }

    if (eventType === CargoEventTypeEnum.Transport) {
      const transportEventType = (eventType as unknown) as TransportEventTypeEnum;
      const transportEventClassifier = (eventClassifier as unknown) as TransportEventClassifierEnum;
      if (transportCallLocationTimestamp) {
        await createNewTransportEvent({
          transportCallId: transportCallIdToSave,
          eventSubType,
          eventType: transportEventType,
          eventClassifier: transportEventClassifier,
          transportCallLocationId,
          transportCallLocationZone,
          transportCallLocationTimestamp,
          carrierId: carrierShipmentData?.carrierShipment?.carrierId,
        });
      } else {
        const transportEvents = carrierShipmentTransportEvents.filter(
          (t) =>
            t.subType === eventSubType &&
            t.classifier === transportEventClassifier &&
            t.transportCallId === transportCallIdToSave,
        );
        transportEvents.forEach((t) => {
          t.isActive = false;
        });
        await saveAllTransportEvents(transportEvents);
      }
    }
    await reindexCargoByCargoId(cargoId);
    setTimeout(() => {
      refetchAllData();
      setIsSaving(false);
      document.querySelectorAll('.ant-picker-focused').forEach((picker) => {
        picker.classList.remove('ant-picker-focused');
      });
    }, 300);
  };

  return {
    onChangeDate,
    isSaving,
    originalEventDate,
  };
}
