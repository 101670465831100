import {
  Configuration,
  VehicleEntityControllerApi,
  VehicleRequestBody,
  VehicleRequestBodyModeEnum,
  VehicleSearchControllerApi,
} from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { shipmentTrackerBasePath } from '../constants';

export function useVehicleApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const vehicleApi = new VehicleEntityControllerApi(apiConfiguration);
  const vehicleSearchApi = new VehicleSearchControllerApi(apiConfiguration);

  const searchVehicleByImoNumber = async (imoNumber: string) =>
    vehicleSearchApi
      .findByImoNumber(imoNumber)
      // eslint-disable-next-line no-underscore-dangle
      .then((entityModelPayload) => entityModelPayload.data._embedded?.vehicles?.[0]);

  const createVehicle = async (vehicleRequestBody: VehicleRequestBody) =>
    vehicleApi
      .postCollectionResourceVehiclePost(vehicleRequestBody)
      .then((entityModelPayload) => {
        notification.success({
          message: 'Vehicle updated in transport call',
          description: `Vehicle updated successfully`,
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error updating vehicle in transport call',
          description: `Error: ${error.message} ${error?.response?.data?.error}.`,
          placement: 'bottomRight',
          duration: 7,
        });
      });

  const createOceanVehicleByImoNumber = async (imoNumber: string, displayName: string) =>
    createVehicle({
      displayName,
      identification: { imoNumber },
      mode: VehicleRequestBodyModeEnum.Ocean,
    });

  return {
    createOceanVehicleByImoNumber,
    searchVehicleByImoNumber,
  };
}
