import React, { useMemo, useRef, useState } from 'react';
import { Select } from 'antd';
import { sortBy } from 'lodash';
import { Carrier, getAll } from '@beacon-devops/carrier-provider';
import { Box } from '@beacon-devops/components';
import useDebounce from '@utils/hooks/useDebouncedValue';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { SearchField } from '../../types';
import { FormLabel } from '../form';
import { useUpdateCarrier } from './useUpdateCarrier';

interface CarrierSearchProps {
  label: string;
  disabled?: boolean;
  mode?: Mode;
}

const compare = (text: string, textToCompare: string): boolean =>
  text.toLowerCase().includes(textToCompare.trim().toLowerCase());

const filterWithoutNmftaCode = (carriers: Carrier[]) =>
  carriers.filter((c) => !(c.nmftaCode === undefined || c.nmftaCode === ''));

const filterAirCarriers = (carriers: Carrier[]) => carriers.filter((c) => c.mode === Mode.Air);

export const CarrierSearch = ({ label, disabled = false, mode = Mode.Ocean }: CarrierSearchProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const { carrierShipmentData } = useShipmentDetailPageCargoData();
  const selectRef = useRef<HTMLElement>(null);
  const { updateCarrier, isSaving } = useUpdateCarrier();

  const carriers = useMemo(() => {
    return mode === Mode.Air ? filterAirCarriers(getAll()) : filterWithoutNmftaCode(getAll());
  }, [mode]);

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const filteredCarriers: SearchField[] = useMemo(() => {
    const transformedCarriers = carriers.reduce((acc: SearchField[], carrier) => {
      const isSearched =
        compare(carrier.nmftaCode || '', debouncedSearchValue) || compare(carrier.displayName, debouncedSearchValue);

      if (isSearched) {
        return [
          ...acc,
          {
            id: carrier.id,
            name: carrier.nmftaCode ? `${carrier.nmftaCode} (${carrier.displayName})` : carrier.displayName,
            mawbPrefix: carrier.mawbPrefix,
          },
        ];
      }
      return acc;
    }, []);

    return sortBy(transformedCarriers, 'label');
  }, [carriers, debouncedSearchValue]);

  const initialValue = carrierShipmentData?.carrierShipment?.carrierId;

  async function onSelect(value: string) {
    await updateCarrier(value);
    (selectRef.current as HTMLElement)?.blur();
  }

  return (
    <Box width='100%'>
      <FormLabel>{label}</FormLabel>
      <Select
        style={{ width: '100%' }}
        onSearch={setSearchValue}
        filterOption={false}
        showSearch
        placeholder='Please select a carrier'
        onSelect={onSelect}
        value={initialValue}
        ref={selectRef}
        disabled={disabled || isSaving}
      >
        {filteredCarriers?.map(({ name, id }) => (
          <Select.Option value={id} key={id} title={name}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Box>
  );
};
