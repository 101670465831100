import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';

interface Args {
  dischargeTransportCallId: string;
}

export function useVesselByDischargeTransportCallId({ dischargeTransportCallId }: Args) {
  const { carrierShipmentData, carrierShipmentTransportCalls } = useShipmentDetailPageCargoData();
  const transportCallByDischargeId = carrierShipmentTransportCalls.find(
    (transportCall) => transportCall.id === dischargeTransportCallId,
  );
  const vehicleByDischargeTransportCallId = carrierShipmentData?.vehicles?.find(
    (vehicle) => vehicle.id === transportCallByDischargeId?.vehicleId,
  );

  const vesselValue = `${vehicleByDischargeTransportCallId?.displayName}-${vehicleByDischargeTransportCallId?.identification?.imoNumber}`;

  return {
    vesselValue,
    vesselName: vehicleByDischargeTransportCallId?.displayName,
    vesselImoNumber: vehicleByDischargeTransportCallId?.identification?.imoNumber,
  };
}
