import React from 'react';
import { useParams } from 'react-router';
import { Select } from 'antd';
import { get } from 'lodash';
import { useSearchCargoForCargoSwitcher } from '@queries/graphql';
import { Cargo, SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { AIR_CONTAINER_PATH } from '@features/shipmentList/routes/paths';
import { CargoPageRouteParams } from '../../types';
import { CargoToggleContainer } from './styles';

interface AirCargoToggleProps {
  carrierShipmentId: string;
}

const AirCargoToggle = ({ carrierShipmentId }: AirCargoToggleProps) => {
  const { data, isLoading } = useSearchCargoForCargoSwitcher({
    criteria: [
      {
        field: 'carrierShipmentId',
        condition: SearchConditionType.Equals,
        values: [carrierShipmentId],
      },
    ],
    page: 1,
    size: 20,
    sort: [],
  });

  const { customerId, cargoId } = useParams<CargoPageRouteParams>();

  const cargoList = get(data, 'cargos', []) as Cargo[];

  return (
    <CargoToggleContainer>
      <Select
        loading={isLoading}
        showSearch
        value={cargoId}
        onSelect={(value: string) => {
          if (value !== cargoId) {
            const cargoURL = AIR_CONTAINER_PATH.replace(':customerId', customerId).replace(':cargoId', value);
            window.open(cargoURL, '_blank');
          }
        }}
      >
        {cargoList?.map((cargoItem) => (
          <Select.Option key={cargoItem.id || ''} value={cargoItem.id || ''} disabled={cargoItem.id === cargoId}>
            {cargoItem?.numberOfPackages || '0'} Piece(s) | {cargoItem?.weight}&nbsp;
            {cargoItem?.weightUnit}
          </Select.Option>
        ))}
      </Select>
    </CargoToggleContainer>
  );
};

export default AirCargoToggle;
