import { useParams } from 'react-router-dom';
import { useFindCarrierShipmentWithDependenciesByCargoId } from '@services/ShipmentTrackerService/hooks';
import { get } from 'lodash';
import { CargoPageRouteParams } from '../types';
import { useSdgCargoForShipmentDetailPage } from './useSdgCargoForShipmentDetailPage';
import { getOrderedTransportCallIds } from '../utils/common';

export function useShipmentDetailPageCargoData() {
  const { cargoId } = useParams<CargoPageRouteParams>();

  const {
    cargoDoNotUse,
    isErrorLoadingSearchedCargo,
    errorLoadingSearchedCargo,
    isLoadingSearchedCargo,
    refetchSearchedCargo,
    cargoTransportCurrentLeg,
  } = useSdgCargoForShipmentDetailPage(cargoId);

  const {
    data: carrierShipmentWithDependencies,
    isLoading: isLoadingCarrierShipmentWithDependencies,
    refetch: refetchCarrierShipmentWithDependencies,
  } = useFindCarrierShipmentWithDependenciesByCargoId(cargoId);

  const carrierShipmentId = carrierShipmentWithDependencies?.carrierShipment?.id || '';
  const cargoEvents = (carrierShipmentWithDependencies?.cargoEvents || []).filter((e) => e.cargoId === cargoId);
  const carrierShipmentReferences = carrierShipmentWithDependencies?.shipmentReference || [];
  const cargoTransports = (carrierShipmentWithDependencies?.transports || []).filter((t) => t.cargoId === cargoId);
  const cargoTransportLegs = cargoTransports.length;
  const cargoHasTranshipments = cargoTransportLegs > 1;

  const shipmentTrackerCargo = (carrierShipmentWithDependencies?.cargos || []).find((c) => c.id === cargoId);
  const containerNumber = shipmentTrackerCargo?.container?.containerNumber || '';

  const firstTransport = cargoTransports.find(
    (t) => t.sequenceNo === (cargoHasTranshipments ? 1 : cargoTransportCurrentLeg),
  );
  const firstJourneyFlightNumber = get(firstTransport, 'journey.references.flightNumber', '');

  const carrierShipmentTransportCalls = carrierShipmentWithDependencies?.transportCalls || [];
  const carrierShipmentTransportEvents = carrierShipmentWithDependencies?.transportEvents || [];

  function refetchAllData() {
    refetchSearchedCargo();
    refetchCarrierShipmentWithDependencies();
  }

  const orderedTransportCallIds = getOrderedTransportCallIds({
    legs: cargoTransportLegs,
    currentLeg: cargoTransportCurrentLeg,
    transports: cargoTransports,
  });

  return {
    cargoEvents,
    firstJourneyFlightNumber,
    carrierShipmentReferences,
    carrierShipmentTransportCalls,
    cargoTransportLegs,
    cargoTransportCurrentLeg,
    containerNumber,
    carrierShipmentId,
    cargoId,
    carrierShipmentData: carrierShipmentWithDependencies,
    isLoadingSearchedCargo,
    isErrorLoadingSearchedCargo,
    errorLoadingSearchedCargo,
    refetchSearchedCargo,
    doNotUseSdgCargo: cargoDoNotUse,
    shipmentTrackerCargo,
    cargoHasTranshipments,
    carrierShipmentTransportEvents,
    isLoadingCarrierShipmentData: isLoadingCarrierShipmentWithDependencies,
    refetchCarrierShipmentWithDependencies,
    refetchAllData,
    orderedTransportCallIds,
  };
}
