import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Button, Tag } from 'antd';
import { UseMutateFunction } from 'react-query';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { SignalType } from '@beacon-devops/container-tracking-api-model-ts-client';
import { Customer } from '@beacon-devops/customer-service-client';
import { CUSTOMER_DETAIL_ROUTE } from '@routes/constants';
import { useIsCTACargoTrackingActive } from '@queries/containers';
import AirCargoToggle from '@features/shipmentDetail/components/AirCargoToggle';
import { CargoStatusEnum } from '@beacon-devops/shipment-tracker-client';
import { ManualCTATrackingMutationVariables, SCVContainerFormValues } from '../../types';
import {
  DeletedText,
  DisableTrackingButton,
  EnableTrackingButton,
  JSONButton,
  TitleBar,
  TitleButtonsContainer,
  TitleContainer,
  TitleWithoutMargin,
  TrackingStatusButton,
} from './styles';

const getIdentifier = (mode: Mode, data?: SCVContainerFormValues | null) => {
  switch (mode) {
    case Mode.Ocean:
      return data?.identifier;
    case Mode.Air:
      return data?.mawb;
    default:
      return data?.identifier;
  }
};

interface ScvContainerDetailsHeaderProps {
  containerId: string;
  mode: Mode;
  data?: SCVContainerFormValues | null;
  customer?: Customer;
  isSubmitDisabled: boolean;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  isDeleting: boolean;
  updateManualTracking: UseMutateFunction<unknown, unknown, ManualCTATrackingMutationVariables>;
  isUpdatingTracking: boolean;
  setJSONModalOpen: Dispatch<SetStateAction<boolean>>;
  setPurgeModalOpen: Dispatch<SetStateAction<boolean>>;
}

const TitleActionSection = ({
  data,
  mode,
  isSubmitDisabled,
  updateManualTracking,
  isUpdatingTracking,
  setDeleteModalOpen,
  isDeleting,
  setJSONModalOpen,
  setPurgeModalOpen,
}: ScvContainerDetailsHeaderProps) => {
  const identifier = getIdentifier(mode, data);

  const { data: state } = useIsCTACargoTrackingActive(identifier || '', mode);
  const isCargoTrackingActive = state?.isTrackingActive ?? false;
  const isCargoTrackingPaused = state?.isTrackingPaused ?? false;

  return (
    <TitleButtonsContainer>
      <Button htmlType='submit' disabled={isSubmitDisabled} type='primary'>
        Submit
      </Button>

      <TrackingStatusButton type='primary' $isTrackingActive={isCargoTrackingActive}>
        {isCargoTrackingActive ? 'Tracked' : 'Not Tracked'}
      </TrackingStatusButton>

      {isCargoTrackingPaused ? (
        <EnableTrackingButton
          onClick={() => {
            updateManualTracking({ signal: SignalType.Start });
          }}
          loading={isUpdatingTracking}
          disabled={!isCargoTrackingActive || isUpdatingTracking}
          data-testid='enable-tracking-button'
        >
          Unpause (Start)
        </EnableTrackingButton>
      ) : (
        <DisableTrackingButton
          onClick={() => {
            updateManualTracking({ signal: SignalType.Stop });
          }}
          loading={isUpdatingTracking}
          disabled={!isCargoTrackingActive || isUpdatingTracking}
          data-testid='disable-tracking-button'
        >
          Pause (Stop)
        </DisableTrackingButton>
      )}

      <JSONButton type='default' onClick={() => setJSONModalOpen(true)}>
        Show JSON
      </JSONButton>

      <Button type='default' onClick={() => setPurgeModalOpen(true)} danger>
        Purge Cargo
      </Button>

      {data?.status !== CargoStatusEnum.Cancelled ? (
        <Button
          onClick={() => setDeleteModalOpen(true)}
          loading={isDeleting}
          disabled={isDeleting}
          data-testid='delete-button'
          danger
        >
          Delete
        </Button>
      ) : (
        <Tag color='error'>
          <DeletedText data-testid='deleted-text'>Deleted</DeletedText>
        </Tag>
      )}

      {mode === Mode.Air && <AirCargoToggle carrierShipmentId={data?.correlationIds.carrierShipmentId || ''} />}
    </TitleButtonsContainer>
  );
};

export const ScvContainerDetailsHeader = (props: ScvContainerDetailsHeaderProps) => {
  const { data, mode = Mode.Ocean, customer } = props;

  const identifier = getIdentifier(mode, data);
  const containerLabel = mode === Mode.Ocean ? 'Container' : 'MAWB';
  const getSpanWithTestId = identifier ? <span data-testid='test-external-id'>{identifier}</span> : null;

  return (
    <TitleBar>
      <TitleContainer>
        <TitleWithoutMargin level={3}>
          {containerLabel}&nbsp;
          {getSpanWithTestId && <>- {getSpanWithTestId}&nbsp;</>}
          -&nbsp;
          <Link to={CUSTOMER_DETAIL_ROUTE.replace(':id', customer?.customerId || '')} target='_blank'>
            {customer?.fullCompanyName}
          </Link>
        </TitleWithoutMargin>
      </TitleContainer>

      {identifier && <TitleActionSection {...props} />}
    </TitleBar>
  );
};
