import { JourneyReferences } from '@beacon-devops/shipment-tracker-client';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { useCargoControllerApi } from '@services/ShipmentTrackerService/cargoControllerApi/useCargoControllerApi';
import { useJourneyApi } from '@services/ShipmentTrackerService/journeyApi/useJourneyApi';
import { useState } from 'react';

// No need to export this function, it's only used internally
function useUpdateJourneyReferenceNumber() {
  const { updateJourneyReferences } = useJourneyApi();
  const [isSaving, setIsSaving] = useState(false);
  const { refetchAllData, cargoId } = useShipmentDetailPageCargoData();
  const { reindexCargoByCargoId } = useCargoControllerApi();
  const updateJourneyReferenceNumber = async (journeyId?: string, references?: JourneyReferences) => {
    if (!journeyId || !references) {
      return;
    }

    setIsSaving(true);
    await updateJourneyReferences(journeyId, references);

    await reindexCargoByCargoId(cargoId);
    setTimeout(() => {
      refetchAllData();
      setIsSaving(false);
    }, 300);
  };

  return { updateJourneyReferenceNumber, isSaving };
}

export function useUpdateVoyageNumber() {
  const { updateJourneyReferenceNumber, isSaving } = useUpdateJourneyReferenceNumber();
  const updateVoyageNumber = async (journeyId?: string, voyageNumber?: string) => {
    await updateJourneyReferenceNumber(journeyId, { voyageNumber });
  };

  return { updateVoyageNumber, isSaving };
}

export function useUpdateFlightNumber() {
  const { updateJourneyReferenceNumber, isSaving } = useUpdateJourneyReferenceNumber();
  const updateFlightNumber = async (journeyId?: string, flightNumber?: string) => {
    await updateJourneyReferenceNumber(journeyId, { flightNumber });
  };

  return { updateFlightNumber, isSaving };
}
