// react query hook regarding getAggregatedDataForCarrierShipment service call

import { useQuery } from 'react-query';
import { findCarrierShipmentWithDependenciesByCargoId } from './ShipmentTrackerService';

export function useFindCarrierShipmentWithDependenciesByCargoId(cargoId: string) {
  return useQuery({
    queryKey: ['carrierShipmentWithDependenciesByCargoId', cargoId],
    queryFn: () => findCarrierShipmentWithDependenciesByCargoId(cargoId),
    // We want to trigger this manually
    enabled: false,
  });
}
