import { Cargo } from '@beacon-devops/graphql-typescript-client';
import { Mode, PatchCargoDTO } from '@beacon-devops/container-tracking-api-model-ts-client';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import {
  handleCarrierShipmentUpdate,
  handleEquipmentKindUpdate,
  handleOceanTransportUpdate,
} from './handlesUpdateCargoOcean';
import { handleAirCarrierShipmentUpdate, handleAirTransportUpdate } from './handlesUpdateCargoAir';
import { SCVContainerFormValues } from '../types';

export const handleCargoFormSubmit = (
  originalCargo: Cargo,
  formData: SCVContainerFormValues,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<SCVContainerFormValues>>>,
  mode: Mode = Mode.Ocean,
): PatchCargoDTO => {
  let patchCargoDTO: PatchCargoDTO = {
    correlationIds: formData.correlationIds,
    identifier: formData.identifier,
    mode,
    shipmentReferences: [],
    carrierShipments: [],
    cargos: [],
    cargoEvents: [],
    transportEvents: [],
    oceanVehicles: [],
    journeys: [],
  };

  const isOcean = mode === Mode.Ocean;
  const isAir = mode === Mode.Air;

  if ((dirtyFields.carrier || dirtyFields.departurePort || dirtyFields.arrivalPort) && isOcean) {
    patchCargoDTO.carrierShipments?.push(handleCarrierShipmentUpdate(formData));
  }

  if (isAir) {
    patchCargoDTO.carrierShipments?.push(handleAirCarrierShipmentUpdate(formData));
  }

  if (dirtyFields.equipmentKind) {
    patchCargoDTO.cargos?.push(handleEquipmentKindUpdate(formData));
  }

  if (dirtyFields?.transhipments?.length) {
    dirtyFields?.transhipments?.forEach((transhipment, index) => {
      if (isOcean && (transhipment.port || transhipment.voyagerNumber || transhipment.vessel)) {
        patchCargoDTO = {
          ...patchCargoDTO,
          ...handleOceanTransportUpdate({
            originalCargo,
            formData,
          }),
        };
      }

      if (isAir && (transhipment.port || transhipment.flightNumber)) {
        patchCargoDTO = {
          ...patchCargoDTO,
          ...handleAirTransportUpdate({
            originalCargo,
            formData,
          }),
        };
      }
    });
  }

  if (
    isOcean &&
    (dirtyFields.departurePort || dirtyFields.arrivalPort || dirtyFields.voyagerNumber || dirtyFields.vessel)
  ) {
    patchCargoDTO = {
      ...patchCargoDTO,
      ...handleOceanTransportUpdate({
        originalCargo,
        formData,
      }),
    };
  }

  if (isAir && (dirtyFields.departurePort || dirtyFields.arrivalPort || dirtyFields.flightNumber)) {
    patchCargoDTO = {
      ...patchCargoDTO,
      ...handleAirTransportUpdate({
        originalCargo,
        formData,
      }),
    };
  }

  return patchCargoDTO;
};
