import { getAll as getAllCarriers } from '@beacon-devops/carrier-provider';
import { Mode, ShipmentReferenceTypeCode } from '@beacon-devops/graphql-typescript-client';
import { find, get } from 'lodash';
import {
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  TransportEventSubTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { useParams } from 'react-router-dom';
import { CargoPageRouteParams, SCVContainerFormValues, TranshipmentFields } from '../types';
import { useShipmentDetailPageCargoData } from './useShipmentDetailPageData';
import { getLocationZone, mapEquipmentISOCodeToKind, mapLatestCargoOrTransportEventDateTime } from '../utils/common';

export const useMapSCVCargoFormatToForm = (mode: Mode): SCVContainerFormValues => {
  const { customerId } = useParams<CargoPageRouteParams>();
  const {
    firstJourneyFlightNumber,
    orderedTransportCallIds,
    carrierShipmentTransportCalls,
    cargoEvents,
    carrierShipmentReferences,
    carrierShipmentData,
    carrierShipmentId,
    shipmentTrackerCargo,
    cargoTransportLegs,
    cargoHasTranshipments,
  } = useShipmentDetailPageCargoData();

  const loadTransportCallId = orderedTransportCallIds[0].loadTransportCallId || '';
  const loadTransportCall = carrierShipmentTransportCalls.find((t) => t.id === loadTransportCallId);
  const loadTransportCallLocation = carrierShipmentData?.locations?.find((l) => l.id === loadTransportCall?.locationId);
  const loadTransportCallEvents =
    carrierShipmentData?.transportEvents?.filter((e) => e.transportCallId === loadTransportCallId) || [];

  const dischargeTransportCallId = orderedTransportCallIds[0].dischargeTransportCallId || '';
  const dischargeTransportCall = carrierShipmentTransportCalls.find((t) => t.id === dischargeTransportCallId);
  const dischargeTransportCallEvents =
    carrierShipmentData?.transportEvents?.filter((e) => e.transportCallId === dischargeTransportCallId) || [];
  const dischargeTransportCallLocation = carrierShipmentData?.locations?.find(
    (l) => l.id === dischargeTransportCall?.locationId,
  );

  const journey = carrierShipmentData?.journeys?.find((j) => j.id === loadTransportCall?.outboundJourneyId);
  const vehicle = carrierShipmentData?.vehicles?.find((v) => v.id === loadTransportCall?.vehicleId);

  const polZone = getLocationZone(loadTransportCallLocation?.id || '');
  const podZone = getLocationZone(dischargeTransportCallLocation?.id || '');

  const mawb = get(find(carrierShipmentReferences, { type: ShipmentReferenceTypeCode.Mawb }), 'value') || '';

  const carrier = getAllCarriers().find((c) => c.id === carrierShipmentData?.carrierShipment?.carrierId);

  let cargoInFormFormat: SCVContainerFormValues = {
    correlationIds: {
      customerId,
      carrierShipmentId,
      cargoId: shipmentTrackerCargo?.id || '',
    },
    identifier: shipmentTrackerCargo?.container?.containerNumber || mawb || '',
    status: shipmentTrackerCargo?.status,
    carrier: {
      id: carrier?.id || '',
      name: carrier?.displayName || '',
      mawbPrefix: carrier?.mawbPrefix || '',
    },
    vessel: {
      id: vehicle?.identification?.imoNumber || '',
      name: vehicle?.displayName || '',
    },
    voyagerNumber: journey?.references?.voyageNumber || '',
    equipmentKind: mapEquipmentISOCodeToKind(shipmentTrackerCargo?.container?.isoCode || ''),
    departurePort: {
      id: loadTransportCallLocation?.id || '',
      name: loadTransportCallLocation?.name || '',
    },
    arrivalPort: {
      id: dischargeTransportCallLocation?.id || '',
      name: dischargeTransportCallLocation?.name || '',
    },
    bookingConfirmed: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.BookingConfirmed,
        polZone,
      ),
    },
    receive: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.Receive,
        polZone,
      ),
    },
    gateOutEmpty: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.GateOutEmpty,
        polZone,
      ),
    },
    gateInFull: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.GateInFull,
        polZone,
      ),
    },
    loaded: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.Load,
        polZone,
        loadTransportCallId,
      ),
    },
    discharged: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.Discharge,
        podZone,
        dischargeTransportCallId,
      ),
    },
    gateOutFull: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.GateOutFull,
        podZone,
      ),
    },
    collect: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.Collect,
        podZone,
      ),
    },
    gateInEmpty: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        cargoEvents,
        CargoEventClassifierEnum.Actual,
        CargoEventSubTypeEnum.GateInEmpty,
        podZone,
      ),
    },
    departure: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        loadTransportCallEvents,
        CargoEventClassifierEnum.Actual,
        TransportEventSubTypeEnum.Depart,
        polZone,
      ),
      expectedDate: mapLatestCargoOrTransportEventDateTime(
        loadTransportCallEvents,
        CargoEventClassifierEnum.Estimated,
        TransportEventSubTypeEnum.Depart,
        polZone,
      ),
    },
    arrival: {
      actualDate: mapLatestCargoOrTransportEventDateTime(
        dischargeTransportCallEvents,
        CargoEventClassifierEnum.Actual,
        TransportEventSubTypeEnum.Arrive,
        podZone,
      ),
      expectedDate: mapLatestCargoOrTransportEventDateTime(
        dischargeTransportCallEvents,
        CargoEventClassifierEnum.Estimated,
        TransportEventSubTypeEnum.Arrive,
        podZone,
      ),
    },
    berth: {
      actualDate: cargoHasTranshipments
        ? undefined
        : mapLatestCargoOrTransportEventDateTime(
            dischargeTransportCallEvents,
            CargoEventClassifierEnum.Actual,
            TransportEventSubTypeEnum.Berth,
            podZone,
          ),
    },
    freightForwarderName:
      get(find(carrierShipmentReferences, { type: ShipmentReferenceTypeCode.Ff }), 'partyName') || '',
    masterBillOfLading: get(find(carrierShipmentReferences, { type: ShipmentReferenceTypeCode.Mbol }), 'value') || '',
    mawb,
    pieces: shipmentTrackerCargo?.numberOfPackages?.toString() || '',
    weight: shipmentTrackerCargo?.weight?.toString() || '',
    flightNumber: firstJourneyFlightNumber,
    transhipments: [],
  };

  /*
   * Load T/S into form format
   */
  if (cargoHasTranshipments && cargoInFormFormat.transhipments) {
    for (let currentLeg = 2; currentLeg < cargoTransportLegs + 1; currentLeg += 1) {
      const isLastLeg = currentLeg === cargoTransportLegs;
      const legTransport = carrierShipmentData?.transports?.find((t) => t.sequenceNo === currentLeg);
      const legLoadTransportCallId = legTransport?.loadTransportCallId || '';
      const legLoadTransportCall = carrierShipmentTransportCalls.find((t) => t.id === legLoadTransportCallId);
      const legLoadTransportCallLocation = carrierShipmentData?.locations?.find(
        (l) => l.id === legLoadTransportCall?.locationId,
      );
      const legLoadTransportCallEvents =
        carrierShipmentData?.transportEvents?.filter((e) => e.transportCallId === legLoadTransportCallId) || [];

      const legDischargeTransportCallId = legTransport?.dischargeTransportCallId || '';
      const legDischargeTransportCall = carrierShipmentTransportCalls.find((t) => t.id === legDischargeTransportCallId);
      const legDischargeTransportCallLocation = carrierShipmentData?.locations?.find(
        (l) => l.id === legDischargeTransportCall?.locationId,
      );
      const legDischargeTransportCallEvents =
        carrierShipmentData?.transportEvents?.filter((e) => e.transportCallId === legDischargeTransportCallId) || [];

      const legJourney = carrierShipmentData?.journeys?.find((j) => j.id === legLoadTransportCall?.outboundJourneyId);
      const legVehicle = carrierShipmentData?.vehicles?.find((v) => v.id === legLoadTransportCall?.vehicleId);

      const legPolZone = getLocationZone(loadTransportCallLocation?.id || '');
      const legPodZone = getLocationZone(dischargeTransportCallLocation?.id || '');

      const previousLegTransport = carrierShipmentData?.transports?.find((t) => t.sequenceNo === currentLeg - 1);
      const previousLegDischargeTransportCallId = previousLegTransport?.dischargeTransportCallId || '';

      const transhipment: TranshipmentFields = {
        flightNumber: legJourney?.references?.flightNumber || '',
        port: {
          id: legLoadTransportCallLocation?.id || '',
          name: legLoadTransportCallLocation?.name || '',
        },
        discharged: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            cargoEvents,
            CargoEventClassifierEnum.Actual,
            CargoEventSubTypeEnum.Discharge,
            legPolZone,
            mode === Mode.Air ? legDischargeTransportCallId : previousLegDischargeTransportCallId,
          ),
        },
        loaded: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            cargoEvents,
            CargoEventClassifierEnum.Actual,
            CargoEventSubTypeEnum.Load,
            legPolZone,
            legLoadTransportCallId,
          ),
        },
        vessel: {
          id: legVehicle?.identification?.imoNumber || '',
          name: legVehicle?.displayName || '',
        },
        voyagerNumber: legJourney?.references?.voyageNumber || '',
        departure: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            legLoadTransportCallEvents,
            CargoEventClassifierEnum.Actual,
            TransportEventSubTypeEnum.Depart,
            legPolZone,
          ),
          expectedDate: mapLatestCargoOrTransportEventDateTime(
            legLoadTransportCallEvents,
            CargoEventClassifierEnum.Estimated,
            TransportEventSubTypeEnum.Depart,
            legPolZone,
          ),
        },
        arrival: {
          actualDate: mapLatestCargoOrTransportEventDateTime(
            legDischargeTransportCallEvents,
            CargoEventClassifierEnum.Actual,
            TransportEventSubTypeEnum.Arrive,
            legPodZone,
          ),
          expectedDate: mapLatestCargoOrTransportEventDateTime(
            legDischargeTransportCallEvents,
            CargoEventClassifierEnum.Estimated,
            TransportEventSubTypeEnum.Arrive,
            legPodZone,
          ),
        },
        berth: {
          actualDate: isLastLeg
            ? mapLatestCargoOrTransportEventDateTime(
                legDischargeTransportCallEvents,
                CargoEventClassifierEnum.Actual,
                TransportEventSubTypeEnum.Berth,
                legPodZone,
              )
            : undefined,
        },
      };

      cargoInFormFormat.transhipments.push(transhipment);

      // IF is last leg the discharge transport call location will be the final destination
      if (isLastLeg) {
        cargoInFormFormat.arrivalPort = {
          id: legDischargeTransportCallLocation?.id || '',
          name: legDischargeTransportCallLocation?.name || '',
        };

        if (mode === Mode.Ocean) {
          cargoInFormFormat.discharged = {
            actualDate: mapLatestCargoOrTransportEventDateTime(
              cargoEvents,
              CargoEventClassifierEnum.Actual,
              CargoEventSubTypeEnum.Discharge,
              legPolZone,
              legDischargeTransportCallId,
            ),
          };
        }
      }
    }
  }

  return cargoInFormFormat;
};
