import qs from 'query-string';
import { CargoFilterURLEntry, CargoListFilterWidgetType } from '@beacon-types/cargo/cargoFilters';
import { Milestone, MilestoneType, Mode } from '@beacon-devops/graphql-typescript-client';
import { EventDateTime } from '@beacon-devops/container-tracking-api-model-ts-client';
import { find, get } from 'lodash';
import { CargoFilterWidgetList } from '@features/shipmentList/constants/cargoFilterWidgetList';

export const getCargoFiltersFromURL = (
  locationSearch: string,
  mode: Mode = Mode.Ocean,
): CargoListFilterWidgetType[] => {
  let filterWidgetList: CargoListFilterWidgetType[] = [];
  const query = qs.parse(locationSearch);

  if (query.filters) {
    const cargoFilterValues: CargoFilterURLEntry[] = JSON.parse(query.filters as string);
    filterWidgetList = cargoFilterValues.map((filterValue) => {
      const filterObj = CargoFilterWidgetList.find(
        (filterWidget) => filterWidget.key === filterValue.key && filterWidget.supportedModes?.includes(mode),
      ) as CargoListFilterWidgetType;
      return {
        ...filterObj,
        currentValue: filterValue.currentValue,
      };
    });
  }

  return filterWidgetList;
};

export const mapMilestoneEventDateTime = (
  milestones: Milestone[],
  milestoneType: MilestoneType,
  defaultZone?: string,
): EventDateTime => {
  const predicate = find(milestones, { type: milestoneType });

  return {
    date: get(predicate, `dateInfo.eventDateTime.date`, ''),
    timestamp: get(predicate, `dateInfo.eventDateTime.timestamp`, ''),
    zone: get(predicate, `dateInfo.eventDateTime.zone`, defaultZone),
  };
};
