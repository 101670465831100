import { get } from 'lodash';
import {
  Cargo as TrackingCargo,
  CargoStatus,
  CarrierShipment,
  Journey,
  PatchCargoDTO,
  Transport as CTATransport,
  TransportCall,
  WeightUnit as CTATransportWeightUnit,
  VolumeUnit as CTATransportVolumeUnit,
} from '@beacon-devops/container-tracking-api-model-ts-client';
import { Mode, Transport, VolumeUnit, WeightUnit } from '@beacon-devops/graphql-typescript-client';
import { CargoData } from './handlesUpdateCargoOcean';
import { SCVContainerFormValues } from '../types';

/*
 * We need to send this payload within carrierShipments array for CTA everytime for an Air cargo.
 * Independent if there is or not a carrier update
 */
export const handleAirCarrierShipmentUpdate = (formData: SCVContainerFormValues): CarrierShipment => {
  return {
    carrierId: formData.carrier?.id || '',
    primaryLoadLocationId: formData.departurePort?.id || '',
    primaryDischargeLocationId: formData.arrivalPort?.id || '',
  };
};

export const handleAirTransportUpdate = ({
  originalCargo,
  formData,
}: Pick<CargoData, 'originalCargo' | 'formData'>): Partial<PatchCargoDTO> => {
  const temporaryPatchCargoDTO: Partial<PatchCargoDTO> = {
    journeys: [],
    transportCalls: [],
    transports: [],
    cargos: [],
  };

  const legs = originalCargo?.transportSummary?.legs || 1;
  const hasTranshipments = legs > 1;

  const existingTransports =
    get(originalCargo, 'transports.length', 0) > 0 ? originalCargo?.transports : [{} as Transport];

  existingTransports?.forEach((transport) => {
    const sequenceNo = transport.sequenceNo || 1;
    const isFirstLeg = sequenceNo === 1;
    const formTranshipmentIndex = sequenceNo - 2;

    const mode = Mode.Air;

    const journey = transport?.journey;
    const loadTransportCall = transport?.loadTransportCall;
    const dischargeTransportCall = transport?.dischargeTransportCall;

    const formTranshipment = get(formData, `transhipments[${formTranshipmentIndex}]`, undefined);
    const nextFormTranshipment = get(formData, `transhipments[${formTranshipmentIndex + 1}]`, undefined);

    const departurePortId = isFirstLeg ? formData.departurePort?.id : formTranshipment.port.id;
    const arrivalPortId =
      !nextFormTranshipment || !hasTranshipments ? formData.arrivalPort?.id : nextFormTranshipment.port.id;

    const flightNumber = isFirstLeg ? formData.flightNumber : get(formTranshipment, 'flightNumber', '');
    const journeyId = journey?.id || ((temporaryPatchCargoDTO.journeys?.length || 0) + 1).toString();
    if (journey || flightNumber) {
      const journeyPatch: Journey = {
        id: journeyId,
        mode,
        carrierId: formData.carrier?.id,
        departureLocationId: departurePortId,
        arrivalLocationId: arrivalPortId,
        references: {
          flightNumber,
        },
      };
      temporaryPatchCargoDTO.journeys?.push(journeyPatch);
    }

    const loadTransportCallId =
      loadTransportCall?.id || ((temporaryPatchCargoDTO.transportCalls?.length || 0) + 1).toString();
    const departureTransportCall: TransportCall = {
      id: loadTransportCallId,
      mode,
      locationId: departurePortId,
      outboundJourneyId:
        loadTransportCall?.outboundJourneyId ||
        (temporaryPatchCargoDTO.journeys && temporaryPatchCargoDTO.journeys?.length >= 1)
          ? journeyId
          : '',
    };
    temporaryPatchCargoDTO.transportCalls?.push(departureTransportCall);

    const dischargeTransportCallId =
      dischargeTransportCall?.id || ((temporaryPatchCargoDTO.transportCalls?.length || 0) + 1).toString();
    const arrivalTransportCall: TransportCall = {
      id: dischargeTransportCallId,
      mode,
      locationId: arrivalPortId,
      inboundJourneyId:
        dischargeTransportCall?.inboundJourneyId ||
        (temporaryPatchCargoDTO.journeys && temporaryPatchCargoDTO.journeys?.length >= 1)
          ? journeyId
          : '',
    };
    temporaryPatchCargoDTO.transportCalls?.push(arrivalTransportCall);

    const transportPatch: CTATransport = {
      id: transport?.id || ((temporaryPatchCargoDTO.transports?.length || 0) + 1).toString(),
      mode,
      loadTransportCallId,
      dischargeTransportCallId,
    };
    temporaryPatchCargoDTO.transports?.push(transportPatch);
  });

  if (originalCargo.numberOfPackages && originalCargo.weight && originalCargo.volume) {
    const cargoPatch: TrackingCargo = {
      id: originalCargo.id,
      carrierShipmentId: originalCargo.carrierShipmentId,
      status: originalCargo.status as CargoStatus,
      numberOfPackages: originalCargo.numberOfPackages || 1,
      weight: originalCargo.weight,
      weightUnit: (originalCargo.weightUnit || WeightUnit.Kgs) as CTATransportWeightUnit,
      volumeUnit: (originalCargo.volumeUnit || VolumeUnit.Mtq) as CTATransportVolumeUnit,
    };
    temporaryPatchCargoDTO.cargos?.push(cargoPatch);
  }

  return temporaryPatchCargoDTO;
};
