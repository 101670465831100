import { useSearchCargoById } from '@services/graphql/hooks';
/**
 * @deprecated We do not want to use cargo object coming from SDG, and instead rely on shipment tracker data
 * @param cargoId
 * @returns SDG Cargo data for shipment detail page
 */
export function useSdgCargoForShipmentDetailPage(cargoId: string) {
  /**
   * We do not want to use cargo object coming from SDG, and instead rely on carrierShipmentData
   * We have hence named it cargoDoNotUse, as we do not want to use it and discourage usage of it
   * The field exists for the sake of backwards compatibility as there are couple of places where ShipmentTracker
   * cargo is not readily switchable to SDG cargo
   * @deprecated (use shipmentData instead)
   */
  const {
    cargo: cargoDoNotUse,
    isError: isErrorLoadingSearchedCargo,
    error: errorLoadingSearchedCargo,
    isLoading: isLoadingSearchedCargo,
    refetchCargo: refetchSearchedCargo,
  } = useSearchCargoById({
    cargoId,
  });

  const cargoTransportCurrentLeg = cargoDoNotUse.transportSummary?.currentLeg || 0;

  return {
    cargoDoNotUse,
    isErrorLoadingSearchedCargo,
    errorLoadingSearchedCargo,
    isLoadingSearchedCargo,
    refetchSearchedCargo,
    cargoTransportCurrentLeg,
  };
}
