import {
  CarrierShipment,
  CarrierShipmentEntityControllerApi,
  Configuration,
} from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { shipmentTrackerBasePath } from '../constants';
import { carrierShipmentToCarrierShipmentRequestBody } from './utils';

export function useCarrierShipmentApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const carrierShipmentApi = new CarrierShipmentEntityControllerApi(apiConfiguration);

  const getCarrierShipmentById = async (carrierShipmentId: string) => {
    const response = await carrierShipmentApi.getItemResourceCarriershipmentGet(carrierShipmentId);
    return response.data;
  };

  const updateCarrierShipment = async (carrierShipmentId: string, carrierShipment: CarrierShipment) =>
    carrierShipmentApi
      .patchItemResourceCarriershipmentPatch(
        carrierShipmentId,
        carrierShipmentToCarrierShipmentRequestBody(carrierShipment),
      )
      .then((entityModelPayload) => {
        notification.success({
          message: 'Carrier updated',
          description: `Carrier updated successfully`,
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error updating carrier',
          description: `Error: ${error.message} ${error?.response?.data?.error}.`,
          placement: 'bottomRight',
          duration: 7,
        });
      });

  return {
    getCarrierShipmentById,
    updateCarrierShipment,
  };
}
