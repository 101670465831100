import { AllUsersState } from '../reduxTypes';

export const ALL_USER_INITIAL_STATE: AllUsersState = {
  data: [],
  loading: false,
  error: null,
};

export enum AllUsersActions {
  GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR',
}
