import { ColumnType } from 'antd/lib/table';
import { ReportDto } from '@beacon-devops/shipment-data-quality-engine-ts-client';
import { Button, Divider, Tooltip } from 'antd';
import React from 'react';
import { notEmpty } from '@beacon-devops/components';
import moment from 'moment';
import { SetReportArgs } from './FlaggedShipmentList';

export enum FlaggedShipmentListColumnKey {
  customerName = 'customerName',
  releaseAt = 'releaseAt',
  shipmentIdentifier = 'shipmentIdentifier',
  violatedRules = 'violatedRules',
  createdTime = 'createdTime',
  overallStatus = 'overallStatus',
}

interface GetContainerColumnOptions {
  triggerReleasePrompt: (reportId: string) => void;
  triggerDeletePrompt: (reportId: string) => void;
  setReportDetails: React.Dispatch<React.SetStateAction<SetReportArgs | null>>;
}

const formatDate = (value: string) => {
  return notEmpty(value) ? moment(value).format('Do MMM, YYYY HH:mmA') : '';
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'HIGH':
      return 'red';
    case 'MEDIUM':
      return 'orange';
    default:
      return 'green';
  }
};

export const getColumns = ({
  triggerReleasePrompt,
  triggerDeletePrompt,
  setReportDetails,
}: GetContainerColumnOptions): ColumnType<ReportDto>[] => {
  return [
    {
      title: 'Customer',
      className: 'customerName',
      dataIndex: 'customerName',
      key: FlaggedShipmentListColumnKey.customerName,
      fixed: 'left',
      render: (val, quarantinedReport) => (
        <Tooltip placement='bottom' title='Open cargo view'>
          <a
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href={`/platform/${quarantinedReport.mode}/customer/${quarantinedReport.customerId}/cargoId/${quarantinedReport.cargoId}`}
            target='_blank'
            rel='noreferrer'
          >
            <span
              style={{
                color: '#3366CC',
                cursor: 'pointer',
              }}
            >
              {quarantinedReport.customerName}
            </span>
          </a>
        </Tooltip>
      ),
    },
    {
      title: 'Identifier',
      className: 'shipmentIdentifier',
      dataIndex: 'shipmentIdentifier',
      key: FlaggedShipmentListColumnKey.shipmentIdentifier,
      render: (val, quarantinedReport) => quarantinedReport.shipmentIdentifier,
    },
    {
      title: 'Violated Rules',
      className: 'violatedRules',
      dataIndex: 'violatedRules',
      key: FlaggedShipmentListColumnKey.violatedRules,
      render: (val, quarantinedReport) => quarantinedReport.violatedRuleCodes,
    },
    {
      title: 'Overall Status',
      className: 'overallStatus',
      dataIndex: 'overallStatus',
      key: FlaggedShipmentListColumnKey.overallStatus,
      render: (val, quarantinedReport) => (
        <Tooltip placement='bottom' title='View rule results'>
          <Button
            style={{
              color: getStatusColor(quarantinedReport.status ?? ''),
            }}
            type='ghost'
            onClick={() => setReportDetails({ reportId: quarantinedReport.dataId ?? '', fieldName: 'rulesResult' })}
          >
            {quarantinedReport.status}
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Created At',
      className: 'createdTime',
      dataIndex: 'createdTime',
      key: FlaggedShipmentListColumnKey.createdTime,
      render: (val) => formatDate(val ?? ''),
    },
    {
      title: 'Release At',
      className: 'releaseAt',
      dataIndex: 'releaseAt',
      key: FlaggedShipmentListColumnKey.releaseAt,
      render: (val) => formatDate(val ?? ''),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: (val, record) => (
        <>
          <Button
            type='ghost'
            onClick={() => setReportDetails({ reportId: record.dataId ?? '', fieldName: 'dataItem' })}
          >
            View Data
          </Button>
          <Divider type='vertical' />
          <Button type='ghost' onClick={() => triggerReleasePrompt(record.dataId || '')}>
            Release
          </Button>
          <Divider type='vertical' />
          <Button type='ghost' danger onClick={() => triggerDeletePrompt(record.dataId || '')}>
            Delete
          </Button>
        </>
      ),
    },
  ];
};
