import React from 'react';
import { Box } from '@beacon-devops/components';
import { DatePicker } from 'antd';
import {
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  CargoEventTypeEnum,
  TransportEventSubTypeEnum,
} from '@beacon-devops/shipment-tracker-client';

import { FormLabel } from '../form';
import { useUpdateShipmentDate } from './useUpdateShipmentDate';
import type { UpdateShipmentDateArgs } from './useUpdateShipmentDate';

interface BaseProps {
  label: string;
  name: string;
  transportCallId?: string | null;
  eventClassifier: CargoEventClassifierEnum;
}

interface CargoEventProps extends BaseProps {
  eventSubType: CargoEventSubTypeEnum;
  eventType: CargoEventTypeEnum.Cargo;
}

interface TransportEventProps extends BaseProps {
  eventSubType: TransportEventSubTypeEnum;
  eventType: CargoEventTypeEnum.Transport;
}

type Props = CargoEventProps | TransportEventProps;

export function ShipmentDate({ label, name, eventClassifier, eventType, eventSubType, transportCallId }: Props) {
  const hookArgs: UpdateShipmentDateArgs =
    eventType === CargoEventTypeEnum.Cargo
      ? {
          eventClassifier,
          eventSubType,
          eventType: CargoEventTypeEnum.Cargo,
          transportCallId,
        }
      : {
          eventClassifier,
          eventSubType,
          eventType: CargoEventTypeEnum.Transport,
          transportCallId,
        };

  const { onChangeDate, isSaving, originalEventDate } = useUpdateShipmentDate(hookArgs);

  return (
    <Box width='200px'>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <DatePicker
        id={name}
        disabled={isSaving}
        autoFocus={false}
        picker='date'
        style={{ width: '100%' }}
        value={originalEventDate}
        onChange={onChangeDate}
        showTime
      />
    </Box>
  );
}
