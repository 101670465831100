import {
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  CargoEventTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import React from 'react';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { LocationType } from '../../types';
import { ShipmentDate } from '../ShipmentDate';
import { PortSearch } from '../PortSearch';

type PortOfLoadingSectionProps = {
  mode: Mode;
};

export function PortOfLoadingSection({ mode }: PortOfLoadingSectionProps) {
  const { orderedTransportCallIds } = useShipmentDetailPageCargoData();
  const firstLoadTransportCallId = orderedTransportCallIds[0].loadTransportCallId || '';

  if (mode === Mode.Ocean) {
    return (
      <>
        <PortSearch
          label='Port of Loading'
          name='departurePort'
          type={LocationType.Seaport}
          transportCallId={firstLoadTransportCallId}
        />
        <ShipmentDate
          label='Gate Out Empty'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.GateOutEmpty}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={firstLoadTransportCallId}
          name='loadTransport.gateOutEmpty.actualDate'
        />
        <ShipmentDate
          label='Gate In Full'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.GateInFull}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={firstLoadTransportCallId}
          name='loadTransport.gateInFull.actualDate'
        />
        <ShipmentDate
          label='Loaded'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.Load}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={firstLoadTransportCallId}
          name='loadTransport.load.actualDate'
        />
      </>
    );
  }

  if (mode === Mode.Air) {
    return (
      <>
        <PortSearch
          label='Airport of Loading'
          name='departurePort'
          type={LocationType.Airport}
          transportCallId={firstLoadTransportCallId}
        />
        <ShipmentDate
          label='Booking Confirmed'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.BookingConfirmed}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={firstLoadTransportCallId}
          name='loadTransport.bookingConfirmed.actualDate'
        />
        <ShipmentDate
          label='Cargo Received'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.Receive}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={firstLoadTransportCallId}
          name='loadTransport.receive.actualDate'
        />
      </>
    );
  }

  return null;
}
