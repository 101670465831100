import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { useCargoControllerApi } from '@services/ShipmentTrackerService/cargoControllerApi/useCargoControllerApi';
import { useTransportCallApi } from '@services/ShipmentTrackerService/transportCallApi/useTransportCallApi';
import { useVehicleApi } from '@services/ShipmentTrackerService/vehicleApi/useVehicleApi';
import { notification } from 'antd';
import { useState } from 'react';

/**
 * This hook is used to update the vessel in the transport calls
 * The vehicle is first searched by the imo number
 * If the vehicle is not found, it is created
 * Then the vehicle is updated in the transport calls
 * @returns {isSaving, updateVesselInTransportCalls}
 */

export function useUpdateVessel() {
  const [isSaving, setIsSaving] = useState(false);
  const { updateVehicleInTransportCall } = useTransportCallApi();
  const { shipmentTrackerCargo, refetchAllData } = useShipmentDetailPageCargoData();
  const { reindexCargoByCargoId } = useCargoControllerApi();
  const { createOceanVehicleByImoNumber, searchVehicleByImoNumber } = useVehicleApi();
  const cargoId = shipmentTrackerCargo?.id || '';
  return {
    isSaving,
    async updateVesselInTransportCalls(
      loadTransportCallId: string,
      dischargeTransportCallId: string,
      vehicleImoNumber: string,
      vehicleName: string,
    ) {
      if (loadTransportCallId && dischargeTransportCallId) {
        setIsSaving(true);
        let vehicle = await searchVehicleByImoNumber(vehicleImoNumber);
        if (!vehicle || !vehicle.id) {
          await createOceanVehicleByImoNumber(vehicleImoNumber, vehicleName);
          vehicle = await searchVehicleByImoNumber(vehicleImoNumber);
        }

        if (vehicle && vehicle.id) {
          await updateVehicleInTransportCall(loadTransportCallId, vehicle.id);
          await updateVehicleInTransportCall(dischargeTransportCallId, vehicle.id);
        }

        notification.success({
          message: 'Vessel updated successfully',
          placement: 'bottomRight',
        });

        await reindexCargoByCargoId(cargoId);
        setTimeout(() => {
          refetchAllData();
          setIsSaving(false);
          document.querySelectorAll('.ant-select-focused').forEach((picker) => {
            picker.classList.remove('ant-select-focused');
          });
        }, 300);
      }
    },
  };
}
