import {
  Configuration,
  JourneyEntityControllerApi,
  JourneyReferences,
  JourneyRequestBody,
} from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { shipmentTrackerBasePath } from '../constants';

export function useJourneyApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const journeyEntityControllerApi = new JourneyEntityControllerApi(apiConfiguration);

  const patchJourney = async (journeyId: string, journeyRequestBody: JourneyRequestBody) =>
    journeyEntityControllerApi
      .patchItemResourceJourneyPatch(journeyId, journeyRequestBody)
      .then((entityModelPayload) => {
        notification.success({
          message: 'Journey updated',
          description: `Journey updated successfully`,
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error updating journey',
          description: `Error: ${error.message} ${error?.response?.data?.error}.`,
          placement: 'bottomRight',
          duration: 7,
        });
      });

  const updateJourneyReferences = async (journeyId: string, references: JourneyReferences) => {
    return patchJourney(journeyId, { references });
  };

  return {
    updateJourneyReferences,
  };
}
