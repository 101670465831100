import React, { ReactElement } from 'react';
import CargoSearchProvider from '@features/shipmentList/components/CargoSearchProvider';
import AirCargoList from '@features/shipmentList/components/AirCargoList';
import PageLayout from '@components/PageLayout';

const AirListingPage = (): ReactElement => {
  return (
    <PageLayout>
      <CargoSearchProvider>
        <AirCargoList />
      </CargoSearchProvider>
    </PageLayout>
  );
};

export default AirListingPage;
