import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { FormLabel } from '../form';
import { useUpdateVoyageNumber, useUpdateFlightNumber } from './useUpdateVoyageOrAirlineNumber';

interface VoyageOrAirlineNumberProps {
  transportCallId: string;
  mode: Mode;
}

export function VoyageOrAirlineNumber({ transportCallId, mode }: VoyageOrAirlineNumberProps) {
  const { carrierShipmentTransportCalls, carrierShipmentData } = useShipmentDetailPageCargoData();
  const [inputValue, setInputValue] = useState('');

  const transportCall = carrierShipmentTransportCalls.find((call) => call.id === transportCallId);
  const journeyId = transportCall?.outboundJourneyId;
  const journey = carrierShipmentData?.journeys?.find((journey) => journey.id === journeyId);
  const voyageNumber = journey?.references?.voyageNumber;
  const flightNumber = journey?.references?.flightNumber;
  useEffect(() => {
    if (mode === Mode.Ocean) {
      setInputValue(voyageNumber || '');
    }

    if (mode === Mode.Air) {
      setInputValue(flightNumber || '');
    }
  }, [voyageNumber, flightNumber]);
  const { updateVoyageNumber, isSaving: isSavingVoyageNumber } = useUpdateVoyageNumber();
  const { updateFlightNumber, isSaving: isSavingFlightNumber } = useUpdateFlightNumber();

  function save() {
    if (mode === Mode.Ocean) {
      updateVoyageNumber(journeyId, inputValue);
    }

    if (mode === Mode.Air) {
      updateFlightNumber(journeyId, inputValue);
    }
  }
  return (
    <>
      <FormLabel>{mode === Mode.Ocean ? 'Voyage Number' : 'Flight Number'}</FormLabel>
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={save}
        onPressEnter={save}
        disabled={isSavingVoyageNumber || isSavingFlightNumber}
      />
    </>
  );
}
