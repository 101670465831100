import {
  CargoControllerApi,
  CargoEntityControllerApi,
  CargoRequestBody,
  Configuration,
} from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { shipmentTrackerBasePath } from '../constants';

export function useCargoControllerApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const cargoControllerApi = new CargoControllerApi(apiConfiguration);
  const cargoEntityControllerApi = new CargoEntityControllerApi(apiConfiguration);
  const reindexCargoByCargoId = async (cargoId: string, priority = true) => {
    return cargoControllerApi
      .reindexByCargoId(cargoId, priority)
      .then((entityModelPayload) => entityModelPayload.data)
      .catch((error) => {
        notification.error({
          message: 'Error reindexing cargo, change might not reflect in Platform',
          description: `Error: ${error.message} ${error?.response?.data?.error}`,
          placement: 'bottomRight',
          duration: 7,
        });
      });
  };

  const patchCargo = async (cargoId: string, cargoRequestBody: CargoRequestBody) => {
    return cargoEntityControllerApi
      .patchItemResourceCargoPatch(cargoId, cargoRequestBody)
      .then((entityModelPayload) => entityModelPayload.data)
      .then(() => {
        notification.success({
          message: 'Cargo updated successfully',
          placement: 'bottomRight',
          duration: 7,
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Error updating cargo',
          description: error.message,
          placement: 'bottomRight',
          duration: 7,
        });
      });
  };

  return { reindexCargoByCargoId, patchCargo };
}
