import {
  Configuration,
  TransportEventEntityControllerApi,
  TransportEventRequestBody,
  TransportEvent,
  EventSourceSourceTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { ISO_WITH_TIMEZONE } from '@constants/date';
import { shipmentTrackerBasePath } from '../constants';
import { CreateTransportEventArgs } from './types';
import {
  mapTransportEventClassifierToTransportEventRequestBodyClassifier,
  mapTransportEventTypeToTransportEventRequestBodyType,
  mapTransportEventSubTypeToTransportEventRequestBodySubType,
} from './utils';

export function useTransportEventApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const transportEventEntityApi = new TransportEventEntityControllerApi(apiConfiguration);
  const createNewTransportEvent = async ({
    transportCallId,
    eventSubType,
    eventType,
    eventClassifier,
    transportCallLocationId,
    transportCallLocationZone,
    transportCallLocationTimestamp,
  }: CreateTransportEventArgs) => {
    const transportEventRequestBody: TransportEventRequestBody = {
      transportCallId,
      subType: mapTransportEventSubTypeToTransportEventRequestBodySubType(eventSubType),
      type: mapTransportEventTypeToTransportEventRequestBodyType(eventType),
      classifier: mapTransportEventClassifierToTransportEventRequestBodyClassifier(eventClassifier),
      locationId: transportCallLocationId,
      eventDateTime: {
        zone: transportCallLocationZone,
        timestamp: transportCallLocationTimestamp.tz(transportCallLocationZone).format(ISO_WITH_TIMEZONE),
      },
      source: {
        sourceType: EventSourceSourceTypeEnum.Operator,
        identifier: 'PLATFORM_CX',
        displayName: 'Beacon Manual Update',
        confidence: 0.9,
      },
    };
    return transportEventEntityApi
      .postCollectionResourceTransporteventPost(transportEventRequestBody)
      .then((entityModelPayload) => {
        notification.success({
          message: 'Transport event created',
          description: `Transport event created successfully`,
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error creating transport event',
          description: `Error: ${error.message} ${error?.response?.data?.error}`,
          placement: 'bottomRight',
          duration: 7,
        });
      });
  };

  const saveAllTransportEvents = async (transportEvents: TransportEvent[]) => {
    return transportEventEntityApi
      .postMultipleTransportEvents(transportEvents)
      .then((entityModelPayload) => {
        notification.success({
          message: 'Transport events deactivated',
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error deactivating transport events',
          description: `Error: ${error.message} ${error?.response?.data?.error}`,
          placement: 'bottomRight',
          duration: 7,
        });
      });
  };

  return { createNewTransportEvent, saveAllTransportEvents };
}
