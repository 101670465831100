import { get } from 'lodash';
import moment from 'moment-timezone';
import { Cargo, EventType, JourneyStatus, Mode, Transport } from '@beacon-devops/graphql-typescript-client';
import {
  Cargo as TrackingCargo,
  CargoEvent,
  CargoEventSubtype,
  CarrierShipment,
  EventClassifierCode,
  EventDateTime,
  Journey,
  JourneyStatusEnum,
  PatchCargoDTO,
  Transport as CTATransport,
  TransportCall,
  TransportEventSubtype,
} from '@beacon-devops/container-tracking-api-model-ts-client';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { ISO_WITH_TIMEZONE, ISO_WITHOUT_TIMEZONE } from '@constants/date';
import { ContainerEventData, SCVContainerEventData, SCVContainerFormValues } from '../types';

export type CargoData = {
  originalCargo: Cargo;
  initialFormData: SCVContainerFormValues;
  formData: SCVContainerFormValues;
  containerEventData?: SCVContainerEventData;
  dirtyFields?: Partial<Readonly<FieldNamesMarkedBoolean<SCVContainerFormValues>>>;
  deletedEventIds?: string[];
};

interface CargoEventsData extends CargoData {
  subType: CargoEventSubtype;
  classifier: EventClassifierCode;
  accessString: string;
  eventData?: ContainerEventData;
  deletedEventIds?: string[];
}

interface TransportEventsData extends CargoData {
  subType: TransportEventSubtype;
  classifier: EventClassifierCode;
  accessString: string;
  eventAccessString: string;
}

export const handleEquipmentKindUpdate = (formData: SCVContainerFormValues): TrackingCargo => {
  return {
    container: {
      equipmentKind: formData.equipmentKind,
    },
  };
};

export const handleCarrierShipmentUpdate = (formData: SCVContainerFormValues): CarrierShipment => {
  return {
    carrierId: formData.carrier?.id || '',
    primaryLoadLocationId: formData.departurePort?.id || '',
    primaryDischargeLocationId: formData.arrivalPort?.id || '',
  };
};

export const formEventDateTime = (input: EventDateTime): EventDateTime => {
  const output: EventDateTime = {
    ...input,
    date: '',
    timestamp: '',
  };
  if (input.timestamp) {
    const timestampInCurrentZone = moment.tz(input.timestamp, ISO_WITHOUT_TIMEZONE, input.zone || 'UTC');
    output.timestamp = timestampInCurrentZone.tz('UTC').format(ISO_WITH_TIMEZONE);

    if (output.timestamp.includes('+00:00')) {
      output.timestamp = output.timestamp.replace('+00:00', 'Z');
    }
  } else if (input.date) {
    output.timestamp = moment(input.date).tz('UTC').format(ISO_WITH_TIMEZONE);
  }
  return output;
};

export const handleCargoEventUpdate = ({
  initialFormData,
  formData,
  subType,
  classifier,
  accessString,
  eventData,
  deletedEventIds = [],
}: CargoEventsData): CargoEvent => {
  const originalEventDateTime = get(initialFormData, accessString, {});
  let newEventDateTime = get(formData, accessString, {});

  const formattedAccessString = accessString.replace('[', '.').replace(']', '');
  const eventToBeDeleted = eventData?.eventID ? deletedEventIds.includes(formattedAccessString) : false;

  const event: CargoEvent = {
    id: eventData?.eventID || '',
    eventDateTime: formEventDateTime(eventToBeDeleted ? originalEventDateTime : newEventDateTime),
    eventType: EventType.Cargo,
    transportCallId: eventData?.transportCallID || '',
    locationId: eventData?.locationID || '',
    subType,
    classifier,
    delete: eventToBeDeleted,
  };

  return event;
};

export const formTransportEventPatchDTO = ({
  initialFormData,
  subType,
  classifier,
  accessString,
  eventAccessString,
  containerEventData,
  formData,
  deletedEventIds = [],
}: TransportEventsData) => {
  const originalEventDateTime: EventDateTime = get(initialFormData, accessString, {});
  const newEventDateTime: EventDateTime = get(formData, accessString, {});

  const eventData = get(containerEventData, eventAccessString, {});

  const formattedAccessString = accessString.replace('[', '.').replace(']', '');
  const eventToBeDeleted = eventData?.eventID ? deletedEventIds.includes(formattedAccessString) : false;

  // If the date/timestamp or zone has been modified, we need to create a new event

  const hasModifiedTimestamp =
    newEventDateTime.timestamp !== originalEventDateTime.timestamp ||
    newEventDateTime.date !== originalEventDateTime.date ||
    newEventDateTime.zone !== originalEventDateTime.zone;

  const event = {
    id: eventData?.eventID && !hasModifiedTimestamp ? eventData.eventID : '1',
    eventDateTime: formEventDateTime(eventToBeDeleted ? originalEventDateTime : newEventDateTime),
    eventType: EventType.Transport,
    transportCallId: eventData?.transportCallID || '1',
    locationId: eventData?.locationID || '',
    subType,
    classifier,
    delete: eventToBeDeleted,
  };

  return event;
};

export const handleOceanTransportUpdate = ({
  originalCargo,
  formData,
}: Pick<CargoData, 'originalCargo' | 'formData'>): Partial<PatchCargoDTO> => {
  const temporaryPatchCargoDTO: Partial<PatchCargoDTO> = {
    journeys: [],
    transportCalls: [],
    transports: [],
    oceanVehicles: [],
  };

  const legs = originalCargo?.transportSummary?.legs || 1;
  const hasTranshipments = legs > 1;

  const existingTransports =
    get(originalCargo, 'transports.length', 0) > 0 ? originalCargo?.transports : [{} as Transport];

  existingTransports?.forEach((transport) => {
    const sequenceNo = transport.sequenceNo || 1;
    const isFirstLeg = sequenceNo === 1;
    const formTranshipmentIndex = sequenceNo - 2;

    const mode = Mode.Ocean;

    const journey = transport?.journey;
    const loadTransportCall = transport?.loadTransportCall;
    const dischargeTransportCall = transport?.dischargeTransportCall;

    const formTranshipment = get(formData, `transhipments[${formTranshipmentIndex}]`, undefined);
    const nextFormTranshipment = get(formData, `transhipments[${formTranshipmentIndex + 1}]`, undefined);

    let vehicleId = '1';
    const legVehicleImoNumber = transport.vehicle?.identification?.imoNumber || '';
    let currentDisplayName = isFirstLeg ? formData.vessel?.name : formTranshipment?.vessel?.name;
    let currentImoNumber = isFirstLeg ? formData.vessel?.id : formTranshipment?.vessel?.id;

    const existingOceanVehicle =
      legVehicleImoNumber === currentImoNumber
        ? temporaryPatchCargoDTO.oceanVehicles?.find((vehicle) => vehicle.imoNumber === legVehicleImoNumber)
        : '';

    if (existingOceanVehicle) {
      vehicleId = existingOceanVehicle.id || '1';
    } else if (currentImoNumber) {
      vehicleId = ((temporaryPatchCargoDTO.oceanVehicles?.length || 0) + 1).toString();

      temporaryPatchCargoDTO.oceanVehicles?.push({
        id: vehicleId,
        mode,
        displayName: currentDisplayName,
        imoNumber: currentImoNumber,
      });
    }

    const departurePortId = isFirstLeg ? formData.departurePort?.id : formTranshipment?.port?.id;
    const arrivalPortId =
      !nextFormTranshipment || !hasTranshipments ? formData.arrivalPort?.id : nextFormTranshipment.port.id;

    const voyageNumber = isFirstLeg ? formData.voyagerNumber : get(formTranshipment, 'voyagerNumber', '');
    const journeyId = journey?.id || ((temporaryPatchCargoDTO.journeys?.length || 0) + 1).toString();
    if (journey || voyageNumber) {
      const journeyPatch: Journey = {
        id: journeyId,
        mode,
        carrierId: formData.carrier?.id,
        departureLocationId: departurePortId,
        arrivalLocationId: arrivalPortId,
        vehicleId:
          temporaryPatchCargoDTO.oceanVehicles && temporaryPatchCargoDTO.oceanVehicles?.length >= 1 ? vehicleId : '',
        status: (journey?.status || JourneyStatus.Scheduled) as JourneyStatusEnum,
        references: {
          voyageNumber,
        },
      };
      temporaryPatchCargoDTO.journeys?.push(journeyPatch);
    }

    const loadTransportCallId =
      loadTransportCall?.id || ((temporaryPatchCargoDTO.transportCalls?.length || 0) + 1).toString();
    const departureTransportCall: TransportCall = {
      id: loadTransportCallId,
      mode,
      locationId: departurePortId,
      vehicleId:
        temporaryPatchCargoDTO.oceanVehicles && temporaryPatchCargoDTO.oceanVehicles?.length >= 1 ? vehicleId : '',
      outboundJourneyId:
        loadTransportCall?.outboundJourneyId ||
        (temporaryPatchCargoDTO.journeys && temporaryPatchCargoDTO.journeys?.length >= 1)
          ? journeyId
          : '',
    };
    temporaryPatchCargoDTO.transportCalls?.push(departureTransportCall);

    const dischargeTransportCallId =
      dischargeTransportCall?.id || ((temporaryPatchCargoDTO.transportCalls?.length || 0) + 1).toString();
    const arrivalTransportCall: TransportCall = {
      id: dischargeTransportCallId,
      mode,
      locationId: arrivalPortId,
      vehicleId:
        temporaryPatchCargoDTO.oceanVehicles && temporaryPatchCargoDTO.oceanVehicles?.length >= 1 ? vehicleId : '',
      inboundJourneyId:
        dischargeTransportCall?.inboundJourneyId ||
        (temporaryPatchCargoDTO.journeys && temporaryPatchCargoDTO.journeys?.length >= 1)
          ? journeyId
          : '',
    };
    temporaryPatchCargoDTO.transportCalls?.push(arrivalTransportCall);

    const transportPatch: CTATransport = {
      id: transport?.id || ((temporaryPatchCargoDTO.transports?.length || 0) + 1).toString(),
      mode,
      loadTransportCallId,
      dischargeTransportCallId,
    };
    temporaryPatchCargoDTO.transports?.push(transportPatch);
  });

  return temporaryPatchCargoDTO;
};
