import { SearchConditionType, Cargo } from '@beacon-devops/graphql-typescript-client';
import { useSearchCargoForAllCustomers } from '@queries/graphql';
import { get } from 'lodash';

interface UseSearchCargoByIdProps {
  cargoId: string;
}

export function useSearchCargoById({ cargoId }: UseSearchCargoByIdProps) {
  const {
    data: searchData,
    isError,
    error,
    isLoading: isLoadingCargo,
    refetch: refetchCargo,
  } = useSearchCargoForAllCustomers({
    criteria: [
      {
        field: 'id',
        condition: SearchConditionType.Equals,
        values: [cargoId],
      },
    ],
  });
  /**
   * We do not want to use cargo object coming from SDG, and instead rely on carrierShipmentData
   * We have hence named it cargoDoNotUse, as we do not want to use it and discourage usage of it
   * The field exists for the sake of backwards compatibility as there are couple of places where ShipmentTracker
   * cargo is not readily switchable to SDG cargo
   * @deprecated (use shipmentData instead)
   */
  return {
    cargo: get(searchData, 'cargos[0]', {}) as Cargo,
    isError,
    error,
    isLoading: isLoadingCargo,
    refetchCargo,
  };
}
