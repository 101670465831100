import { find, get, sortBy } from 'lodash';
import { Location, getAll as getAllLocations } from '@beacon-devops/location-provider';
import { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import {
  CargoEvent,
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  EventDateTime,
  EventSourceSourceTypeEnum,
  Transport,
  TransportEvent,
  TransportEventClassifierEnum,
  TransportEventSubTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { ISO_WITHOUT_TIMEZONE } from '@constants/date';
import moment from 'moment';
import { TransportCall } from '@beacon-devops/graphql-typescript-client';
import { Maybe } from 'yup/es/types';
import { EquipmentKind } from '@beacon-devops/container-tracking-api-model-ts-client';
import { compareDates } from '@utils/generalUtilities';
import { EventValidationRanges, ValidationRange } from './handleValidation';
import { LocationType, SCVContainerFormValues, ContainerEventData } from '../types';

const emptyLocation: Location = {
  id: 'N/A',
  type: 'FACILITY',
  name: '',
  unLocation: { code: '', countryCode: '', countryName: '' },
  zone: '',
};

export const getPortLocations = (locations: Location[], type: LocationType): Location[] => {
  const filteredLocations = locations.filter((x) => x.facility?.type === type);
  return [emptyLocation, ...sortBy(filteredLocations, 'name')];
};

type EventActors = {
  onDelete: () => void;
  onUndelete: () => void;
};

const buildEventActors = (
  deleteActor: (key: string) => void,
  unDeleteActor: (key: string) => void,
  eventKey: string,
): EventActors => {
  return {
    onDelete: () => deleteActor(eventKey),
    onUndelete: () => unDeleteActor(eventKey),
  };
};

export type EventProps = {
  validationRange?: ValidationRange;
  defaultTimeNow?: boolean;
  renderOnUpdate?: boolean;
  name: UseControllerProps<SCVContainerFormValues>['name'];
} & EventActors;

export const buildEventProps = ({
  deleteActor,
  unDeleteActor,
  eventKey,
  eventValidationRanges,
}: {
  deleteActor: (key: string) => void;
  unDeleteActor: (key: string) => void;
  eventKey: UseControllerProps<SCVContainerFormValues>['name'];
  eventValidationRanges?: EventValidationRanges;
}): EventProps => {
  return {
    ...buildEventActors(deleteActor, unDeleteActor, eventKey),
    validationRange: get(eventValidationRanges, eventKey, undefined),
    name: eventKey,
  };
};

const compareToNumbers = (eventBConfidence: number, eventAConfidence: number): number => {
  if (eventBConfidence < eventAConfidence) return -1;
  if (eventBConfidence > eventAConfidence) return 1;
  return 0;
};

/**
 * Returns a comparison result for sorting purposes, when two events are very similar.
 * - First try to find an event with a higher confidence score
 * - If confidence scores are the same, use the most recently created one
 * - If anything goes wrong during the comparisons above, return equals
 */
const compareSimilarEvents = (eventA: CargoEvent, eventB: CargoEvent): number => {
  const eventAConfidence: number = eventA?.source?.confidence || 0.0;
  const eventBConfidence: number = eventB?.source?.confidence || 0.0;

  if (eventA.classifier === eventB.classifier && eventAConfidence === eventBConfidence) {
    // Identical as far as we can tell. Pick the most recent
    const eventADate = eventA?.updatedAt || eventA?.createdAt || '';
    const eventBDate = eventB?.updatedAt || eventB?.createdAt || '';
    return compareDates(eventBDate, eventADate);
  }
  return compareToNumbers(eventBConfidence, eventAConfidence);
};

export const findLatestCargoOrTransportEvent = (
  events: CargoEvent[] | TransportEvent[],
  classifier: CargoEventClassifierEnum | TransportEventClassifierEnum,
  subType: CargoEventSubTypeEnum | TransportEventSubTypeEnum,
  transportCallId?: string,
): CargoEvent | TransportEvent | undefined => {
  let latestEvents = events
    // We don't want to include internal service pseudo-events in the list of milestones. Third party and ShayUI updates are fine
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .filter(
      (event: CargoEvent | TransportEvent) =>
        event.subType === subType &&
        event.classifier === classifier &&
        event?.source?.sourceType !== EventSourceSourceTypeEnum.InternalService,
    )
    .sort(compareSimilarEvents);

  if (transportCallId) {
    latestEvents = latestEvents.filter((event: CargoEvent | TransportEvent) =>
      event?.transportCallId ? event?.transportCallId === transportCallId : true,
    );
  }

  return latestEvents[0];
};

export const getLocationZone = (locationID: string): string => {
  const data = getAllLocations();
  const location = find(data, { id: locationID });
  return get(location, `zone`, '');
};

export const mapLatestCargoOrTransportEventDateTime = (
  events: CargoEvent[] | TransportEvent[],
  classifier: CargoEventClassifierEnum | TransportEventClassifierEnum,
  subType: CargoEventSubTypeEnum | TransportEventSubTypeEnum,
  defaultZone?: string,
  transportCallId?: string,
): EventDateTime => {
  const predicate = findLatestCargoOrTransportEvent(events, classifier, subType, transportCallId);

  const timestamp = predicate?.eventDateTime.timestamp || '';
  const zone = predicate?.eventDateTime?.zone || defaultZone || 'Etc/UTC';

  let returnedTimestamp = '';
  if (timestamp && zone) {
    returnedTimestamp = moment(timestamp).tz(zone).format(ISO_WITHOUT_TIMEZONE);
  } else if (timestamp) {
    returnedTimestamp = moment(timestamp).format(ISO_WITHOUT_TIMEZONE);
  }

  return {
    date: get(predicate, `eventDateTime.date`, ''),
    timestamp: returnedTimestamp,
    zone,
  };
};

export const patchDepartureZone = (
  zone: string,
  formValues: SCVContainerFormValues,
  valueSetter: UseFormSetValue<SCVContainerFormValues>,
) => {
  if (formValues.gateOutEmpty?.actualDate) {
    formValues.gateOutEmpty.actualDate.zone = zone;
    valueSetter('gateOutEmpty', formValues.gateOutEmpty);
  }
  if (formValues.gateInFull?.actualDate) {
    formValues.gateInFull.actualDate.zone = zone;
    valueSetter('gateInFull', formValues.gateInFull);
  }
  if (formValues.loaded?.actualDate) {
    formValues.loaded.actualDate.zone = zone;
    valueSetter('loaded', formValues.loaded);
  }
};

export const patchArrivalZone = (
  zone: string,
  formValues: SCVContainerFormValues,
  valueSetter: UseFormSetValue<SCVContainerFormValues>,
) => {
  if (formValues.discharged?.actualDate) {
    formValues.discharged.actualDate.zone = zone;
    valueSetter('discharged', formValues.discharged);
  }
  if (formValues.gateOutFull?.actualDate) {
    formValues.gateOutFull.actualDate.zone = zone;
    valueSetter('gateOutFull', formValues.gateOutFull);
  }
  if (formValues.gateInEmpty?.actualDate) {
    formValues.gateInEmpty.actualDate.zone = zone;
    valueSetter('gateInEmpty', formValues.gateInEmpty);
  }
};

export const mapCargoEventData = (
  transportCall: Maybe<TransportCall>,
  events: CargoEvent[],
  classifier: CargoEventClassifierEnum,
  subType: CargoEventSubTypeEnum,
): ContainerEventData => {
  let predicate;

  // For these cargo events we don't want to filter with transport call
  if (
    subType === CargoEventSubTypeEnum.GateOutEmpty ||
    subType === CargoEventSubTypeEnum.GateInFull ||
    subType === CargoEventSubTypeEnum.GateOutFull ||
    subType === CargoEventSubTypeEnum.GateInEmpty ||
    subType === CargoEventSubTypeEnum.BookingConfirmed ||
    subType === CargoEventSubTypeEnum.Receive ||
    subType === CargoEventSubTypeEnum.Collect
  ) {
    predicate = findLatestCargoOrTransportEvent(events, classifier, subType);
  } else {
    predicate = findLatestCargoOrTransportEvent(events, classifier, subType, transportCall?.id || '');
  }
  if (predicate) {
    return {
      eventID: get(predicate, `id`, ''),
      transportCallID: get(predicate, `transportCall.id`, ''),
      locationID: get(predicate, `transportCall.location.id`, ''),
    };
  }
  return {
    eventID: get(predicate, `id`, ''),
    transportCallID: transportCall?.id || '',
    locationID: transportCall?.location?.id || '',
  };
};

export const mapTransportEventData = (
  transportCall: Maybe<TransportCall>,
  classifier: CargoEventClassifierEnum,
  subType: TransportEventSubTypeEnum,
): ContainerEventData => {
  const transportEvents = get(transportCall, `transportEvents`, []);
  const predicate = findLatestCargoOrTransportEvent(transportEvents as TransportEvent[], classifier, subType);
  return {
    eventID: get(predicate, `id`, ''),
    transportCallID: transportCall?.id || '',
    locationID: transportCall?.location?.id || '',
  };
};

export const mapEquipmentISOCodeToKind = (isoCode: string): EquipmentKind | undefined => {
  return Object.values(EquipmentKind).find((i: EquipmentKind) => i.endsWith(`_${isoCode}`));
};

type TransportCallIds = {
  loadTransportCallId?: string | null;
  previousDischargeTransportCallId?: string | null;
  dischargeTransportCallId?: string | null;
};

interface Args {
  legs: number;
  currentLeg: number;
  transports: Transport[];
}

/**
 * Returns the ordered transport call ids for the shipment
 * By ordered, we mean the load and discharge transport call ids for each leg in sequence of transports
 * This is used to render the shipment dates for each leg
 * @param legs - The number of legs in the shipment
 * @param currentLeg - The current leg of the shipment
 * @param transports - The transports of the shipment
 * @returns The ordered transport call ids
 */

export const getOrderedTransportCallIds = ({ legs, currentLeg, transports }: Args): TransportCallIds[] => {
  const hasTranshipments = legs > 1;
  const result: TransportCallIds[] = [];

  if (!hasTranshipments) {
    const transport = transports?.find((t) => t.sequenceNo === currentLeg);
    return [
      {
        loadTransportCallId: transport?.loadTransportCallId,
        dischargeTransportCallId: transport?.dischargeTransportCallId,
      },
    ];
  }
  for (let currentLeg = 1; currentLeg <= legs; currentLeg += 1) {
    const transport = transports?.find((t) => t.sequenceNo === currentLeg);

    const transportCallIds: TransportCallIds = {
      loadTransportCallId: transport?.loadTransportCallId,
      dischargeTransportCallId: transport?.dischargeTransportCallId,
    };

    if (hasTranshipments && currentLeg > 1) {
      const previousTransport = transports?.find((t) => t.sequenceNo === currentLeg - 1);
      transportCallIds.previousDischargeTransportCallId = previousTransport?.dischargeTransportCallId;
    }

    result.push(transportCallIds);
  }

  return result;
};
