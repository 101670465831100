import React, { useContext, useState } from 'react';
import TableHeader from '@components/common/TableHeader';
import { Alert, Button, Empty, Table, TablePaginationConfig } from 'antd';
import { DeleteOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { SearchButton } from '@features/shipmentList/components/OceanCargoList/styles';
import { CargoSearchContext } from '@features/shipmentList/components/CargoSearchProvider/context';
import { useHistory, useLocation } from 'react-router';
import { parseCargoURLParamsFromSearch } from '@utils/platform/cargo';
import { useSearchCargoForAllCustomers } from '@queries/graphql';
import { Cargo, Mode, SearchConditionType, ShipmentReferenceTypeCode } from '@beacon-devops/graphql-typescript-client';
import { basePathAir } from '@features/shipmentList/routes/paths';
import { CargoFilterWidgetList } from '@features/shipmentList/constants/cargoFilterWidgetList';
import { getCargoFilterDefaults } from '@utils/common/filters';
import {
  AirCargoListColumnKey,
  getColumns,
} from '@features/shipmentList/components/AirCargoList/AirCargoList.definition';
import { useCustomerIdNameMap } from '@features/shipmentList/hooks/useCustomerIdNameMap';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { isArray } from 'lodash';
import { setQueryParam } from '@utils/location';
import { getTableURLParams } from '@utils/table';
import CargoComplexSearch from '@features/shipmentList/components/CargoComplexSearch';
import { useBulkPurgeShipmentData } from '@queries/shipmentTracker/usePurgeShipmentData';
import { ShipmentPurgeModal } from '../ShipmentPurgeModal';

const AirCargoList = () => {
  const { isOpen: isFiltersOpen, toggleOpen, addFilter, isFilterSelected } = useContext(CargoSearchContext);
  const history = useHistory();
  const location = useLocation();
  const { sort, criteria = [], size, page } = parseCargoURLParamsFromSearch(location.search);

  const {
    data,
    isLoading: isLoadingCargos,
    isError,
    error,
    refetch: refetchSearchedCargos,
  } = useSearchCargoForAllCustomers({
    page,
    size,
    sort: sort || [],
    mode: Mode.Air,
    criteria: [
      {
        field: 'mode',
        condition: SearchConditionType.Equals,
        values: [Mode.Air],
      },
      ...criteria,
    ],
  });

  // Cargo purge functionality
  const [selectedRows, setSelectedRows] = useState<Cargo[]>([]);
  const [isPurgeModalOpen, setPurgeModalOpen] = useState(false);
  const { mutate: bulkPurgeShipmentData } = useBulkPurgeShipmentData(
    selectedRows.map((row) => row.carrierShipmentId || ''),
    () => {
      setTimeout(() => {
        setPurgeModalOpen(false);
        refetchSearchedCargos();
        setSelectedRows([]);
      }, 3000);
    },
    () => {
      setPurgeModalOpen(false);
    },
  );

  const customerIds = data?.cargos ? data?.cargos.map((x) => x?.customerId || '') : [];
  const { customerMap, isLoading: isCustomersLoading } = useCustomerIdNameMap(customerIds);

  const filterIconClickHandler = (columnKey: AirCargoListColumnKey) => {
    if (!isFiltersOpen) {
      const filterWidget = CargoFilterWidgetList.find((widget) => widget.key === AirCargoListColumnKey[columnKey]);
      toggleOpen();

      if (filterWidget) {
        addFilter({ ...filterWidget, currentValue: getCargoFilterDefaults(filterWidget) });
      }
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Cargo> | SorterResult<Cargo>[],
  ) => {
    if (!isArray(sorter)) {
      setQueryParam(
        history,
        getTableURLParams<Cargo>(pagination, sorter) as Record<string, string | undefined | number>,
      );
    }
  };

  let tableColumns = getColumns({ customers: customerMap, filterIconClickHandler, isFilterSelected });
  // if (columnControlValues) {
  //   tableColumns = assignControlledValues(tableColumns, columnControlValues);
  // }

  const shipmentNumbers = selectedRows.map(
    (row) => row.shipmentReferences.find((x) => x.type === ShipmentReferenceTypeCode.Mawb)?.value || '',
  );
  const selectedCargoIds = selectedRows.map((row) => row.id);

  return (
    <>
      <TableHeader
        title='Air Cargo'
        renderRightSide={() => (
          <>
            {selectedCargoIds.length > 0 && (
              <Button type='primary' danger onClick={() => setPurgeModalOpen(true)} style={{ marginRight: '16px' }}>
                <DeleteOutlined /> Purge
              </Button>
            )}
            <Button onClick={() => refetchSearchedCargos()} style={{ marginRight: '16px' }}>
              <ReloadOutlined /> Refresh
            </Button>
            <SearchButton type='primary' ghost onClick={toggleOpen}>
              <SearchOutlined />
              Search
            </SearchButton>
          </>
        )}
      />
      {isError && (
        <Alert
          message='Error fetching containers'
          showIcon
          description={`Error: ${error?.message} - ${error?.response?.data}`}
          type='error'
          action={
            <Button size='small' danger onClick={() => history.push(basePathAir)}>
              Reset
            </Button>
          }
        />
      )}

      <div>
        <Table
          rowSelection={{
            onChange: (_, selectedRows) => setSelectedRows(selectedRows),
            selectedRowKeys: selectedCargoIds,
          }}
          pagination={{
            current: page,
            pageSize: size,
            total: data?.totalRecords || 1,
            position: ['bottomCenter'],
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          dataSource={data ? (data.cargos as Cargo[]) : []}
          rowKey='id'
          bordered={false}
          locale={{
            emptyText: <Empty />,
          }}
          columns={tableColumns}
          loading={isLoadingCargos || isCustomersLoading}
        />
      </div>

      <ShipmentPurgeModal
        isOpen={isPurgeModalOpen}
        shipmentNumbers={shipmentNumbers}
        customer=''
        onCancel={() => setPurgeModalOpen(false)}
        onPurge={() => {
          if (selectedRows.length > 0) {
            bulkPurgeShipmentData();
          }
        }}
      />

      <CargoComplexSearch />
    </>
  );
};

export default AirCargoList;
