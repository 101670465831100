import {
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  CargoEventTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import React from 'react';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { LocationType } from '../../types';
import { ShipmentDate } from '../ShipmentDate';
import { PortSearch } from '../PortSearch';

type PortOfDischargeSectionProps = {
  mode: Mode;
};

export function PortOfDischargeSection({ mode }: PortOfDischargeSectionProps) {
  const { orderedTransportCallIds } = useShipmentDetailPageCargoData();
  const lastDischargeTransportCallId =
    orderedTransportCallIds[orderedTransportCallIds.length - 1].dischargeTransportCallId || '';

  if (mode === Mode.Ocean) {
    return (
      <>
        <PortSearch
          label='Port of Discharge'
          name='arrivalPort'
          type={LocationType.Seaport}
          transportCallId={lastDischargeTransportCallId}
        />
        <ShipmentDate
          label='Discharged'
          name='dischargeTransport.discharge.actualDate'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.Discharge}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={lastDischargeTransportCallId}
        />
        <ShipmentDate
          label='Gate Out Full'
          name='dischargeTransport.gateOutFull.actualDate'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.GateOutFull}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={lastDischargeTransportCallId}
        />
        <ShipmentDate
          label='Gate In Empty'
          name='dischargeTransport.gateInEmpty.actualDate'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.GateInEmpty}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={lastDischargeTransportCallId}
        />
      </>
    );
  }

  if (mode === Mode.Air) {
    return (
      <>
        <PortSearch
          label='Airport of Discharge'
          name='arrivalPort'
          type={LocationType.Airport}
          transportCallId={lastDischargeTransportCallId}
        />
        <ShipmentDate
          label='Cargo Collected'
          name='dischargeTransport.collect.actualDate'
          eventClassifier={CargoEventClassifierEnum.Actual}
          eventSubType={CargoEventSubTypeEnum.Collect}
          eventType={CargoEventTypeEnum.Cargo}
          transportCallId={lastDischargeTransportCallId}
        />
      </>
    );
  }
  return null;
}
