import {
  CargoEventEntityControllerApi,
  CargoEventRequestBody,
  CargoEventSubTypeEnum,
  Configuration,
  EventSourceSourceTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { getAccessToken } from '@services/Auth';
import { notification } from 'antd';
import { ISO_WITH_TIMEZONE } from '@constants/date';
import {
  mapCargoEventSubTypeToCargoEventRequestBodySubType,
  mapCargoEventTypeToCargoEventRequestBodyType,
  mapCargoEventClassifierToCargoEventRequestBodyClassifier,
} from './utils';
import { shipmentTrackerBasePath } from '../constants';
import { CreateCargoEventArgs } from './types';

export function useCargoEventApi() {
  const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken: getAccessToken });
  const cargoEventApi = new CargoEventEntityControllerApi(apiConfiguration);
  const createNewCargoEvent = async ({
    cargoId,
    eventSubType,
    eventType,
    eventClassifier,
    transportCallLocationId,
    transportCallId,
    transportCallLocationZone,
    transportCallLocationTimestamp,
  }: CreateCargoEventArgs) => {
    const cargoEventRequestBody: CargoEventRequestBody = {
      cargoId,
      subType: mapCargoEventSubTypeToCargoEventRequestBodySubType(eventSubType),
      type: mapCargoEventTypeToCargoEventRequestBodyType(eventType),
      classifier: mapCargoEventClassifierToCargoEventRequestBodyClassifier(eventClassifier),
      locationId: transportCallLocationId,
      transportCallId,
      eventDateTime: {
        zone: transportCallLocationZone,
        timestamp: transportCallLocationTimestamp.tz(transportCallLocationZone).format(ISO_WITH_TIMEZONE),
      },
      source: {
        sourceType: EventSourceSourceTypeEnum.Operator,
        identifier: 'PLATFORM_CX',
        displayName: 'Beacon Manual Update',
        confidence: 0.9,
      },
    };
    return cargoEventApi
      .postCollectionResourceCargoeventPost(cargoEventRequestBody)
      .then((entityModelPayload) => {
        notification.success({
          message: 'Cargo event created',
          description: `Cargo event created successfully`,
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error creating cargo event',
          description: `Error: ${error.message} ${error?.response?.data?.error}. Try deleting the date first`,
          placement: 'bottomRight',
          duration: 7,
        });
      });
  };

  const deleteRelatedEventsBySubtype = async (
    cargoId: string,
    eventSubType: CargoEventSubTypeEnum,
    transportCallId: string,
  ) => {
    return cargoEventApi
      .deleteRelatedEventsBySubtype(cargoId, [eventSubType], transportCallId, false)
      .then((entityModelPayload) => {
        notification.success({
          message: 'Cargo event deleted',
          description: `Only operator cargo events have been deleted. You might still see dates due to third party events`,
          placement: 'bottomRight',
          duration: 7,
        });
        return entityModelPayload.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error deleting cargo event',
          description: `Error: ${error.message} ${error?.response?.data?.error}`,
          placement: 'bottomRight',
          duration: 7,
        });
      });
  };

  return { createNewCargoEvent, deleteRelatedEventsBySubtype };
}
