import React, { FC, Fragment } from 'react';
import { Badge } from 'antd';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import TimelineMilestoneIcon from '@assets/icons/Timeline_milestone.svg';
import { Box } from '@beacon-devops/components';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import {
  CargoEventTypeEnum,
  CargoEventClassifierEnum,
  CargoEventSubTypeEnum,
  TransportEventSubTypeEnum,
} from '@beacon-devops/shipment-tracker-client';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { ContainerFromToDisplay } from '../ContainerFromToDisplay';
import { Vessel } from '../Vessel/Vessel';
import { LocationType, SCVContainerFormValues } from '../../types';
import { PortSearch } from '../PortSearch';
import {
  BerthedPlacement,
  CargoLoadedPlacement,
  CargoOffLoadedPlacement,
  CDetailsCard,
  CDetailsCardContent2RowsLayout,
  CDetailsCardContentLayout,
  CDetailsTimelineItem,
  FirstVesselImoPlacement,
  FromToPlacement,
} from '../../pages/OceanContainer/styles';
import { ShipmentDate } from '../ShipmentDate';
import { VoyageOrAirlineNumber } from '../VoyageOrAirlineNumber/VoyageOrAirlineNumber';

interface TranshipmentsSectionProps {
  mode?: Mode;
}

const getPortLabel = (mode: Mode): string => {
  switch (mode) {
    case Mode.Ocean:
      return 'Seaport';
    case Mode.Air:
      return 'Airport';
    default:
      return 'Port';
  }
};

const TranshipmentsSection: FC<TranshipmentsSectionProps> = ({ mode = Mode.Ocean }) => {
  const { control, watch } = useFormContext<SCVContainerFormValues>();
  const { fields } = useFieldArray({
    control,
    name: 'transhipments',
  });

  const portOfDischarge = useWatch({
    control,
    name: 'arrivalPort',
  });

  const watchTranshipments = watch('transhipments');
  const portLabel = getPortLabel(mode);

  const handlePortOfLoading = (position: number) => {
    const thisTranshipment = watchTranshipments ? watchTranshipments[position] : undefined;
    return thisTranshipment ? thisTranshipment?.port?.name : '';
  };

  const handlePortOfDischarge = (position: number) => {
    const nextTranshipment = watchTranshipments ? watchTranshipments[position + 1] : undefined;
    if (nextTranshipment) {
      return `${nextTranshipment?.port?.name || `Transhipment ${portLabel} ${position + 2}`}`;
    }

    return portOfDischarge?.name || '';
  };

  const isOcean = mode === Mode.Ocean;
  const isAir = mode === Mode.Air;

  const { orderedTransportCallIds } = useShipmentDetailPageCargoData();

  // All transport calls except the first one
  const transhipmentsTransportCalls = orderedTransportCallIds.slice(1);

  return (
    <>
      {fields.length
        ? fields.map((field, index) => {
            const outputTranshipmentNumber = index + 1;

            return (
              <Fragment key={field.id}>
                <Badge.Ribbon text={`Transhipment ${outputTranshipmentNumber}`}>
                  <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                    <CDetailsCard mb={8}>
                      <CDetailsCardContentLayout>
                        <PortSearch
                          name={`transhipments.${index}.port`}
                          label={`Transhipment ${portLabel} ${outputTranshipmentNumber}`}
                          type={mode === Mode.Air ? LocationType.Airport : LocationType.Seaport}
                          transportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId || ''}
                        />
                        {isOcean && (
                          <>
                            <ShipmentDate
                              label='Discharged at T/S'
                              name={`transhipments.${index}.discharged.actualDate`}
                              eventSubType={CargoEventSubTypeEnum.Discharge}
                              eventType={CargoEventTypeEnum.Cargo}
                              eventClassifier={CargoEventClassifierEnum.Actual}
                              transportCallId={transhipmentsTransportCalls[index]?.previousDischargeTransportCallId}
                            />
                            <ShipmentDate
                              label='Loaded at T/S'
                              name={`transhipments.${index}.loaded.actualDate`}
                              eventSubType={CargoEventSubTypeEnum.Load}
                              eventType={CargoEventTypeEnum.Cargo}
                              eventClassifier={CargoEventClassifierEnum.Actual}
                              transportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId}
                            />
                          </>
                        )}
                      </CDetailsCardContentLayout>
                    </CDetailsCard>
                    <CDetailsCard>
                      <CDetailsCardContent2RowsLayout>
                        <FromToPlacement>
                          <ContainerFromToDisplay from={handlePortOfLoading(index)} to={handlePortOfDischarge(index)} />
                        </FromToPlacement>

                        <Box>
                          <VoyageOrAirlineNumber
                            transportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId || ''}
                            mode={mode}
                          />
                        </Box>

                        {isOcean && (
                          <FirstVesselImoPlacement>
                            <Vessel
                              label='Vessel'
                              loadTransportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId || ''}
                              dischargeTransportCallId={
                                transhipmentsTransportCalls[index]?.dischargeTransportCallId || ''
                              }
                            />
                          </FirstVesselImoPlacement>
                        )}

                        {isAir && (
                          <CargoLoadedPlacement>
                            <ShipmentDate
                              label='Cargo Loaded'
                              name={`transhipments.${index}.loaded.actualDate`}
                              eventSubType={CargoEventSubTypeEnum.Load}
                              eventType={CargoEventTypeEnum.Cargo}
                              eventClassifier={CargoEventClassifierEnum.Actual}
                              transportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId}
                            />
                          </CargoLoadedPlacement>
                        )}
                        {/* <>{transhipmentsTransportCalls[index]?.loadTransportCallId}</> */}
                        <ShipmentDate
                          label='Estimated Departure'
                          name={`transhipments.${index}.departure.expectedDate`}
                          eventSubType={TransportEventSubTypeEnum.Depart}
                          eventType={CargoEventTypeEnum.Transport}
                          eventClassifier={CargoEventClassifierEnum.Estimated}
                          transportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId}
                        />

                        <ShipmentDate
                          label='Estimated Arrival'
                          name={`transhipments.${index}.arrival.expectedDate`}
                          eventSubType={TransportEventSubTypeEnum.Arrive}
                          eventType={CargoEventTypeEnum.Transport}
                          eventClassifier={CargoEventClassifierEnum.Estimated}
                          transportCallId={transhipmentsTransportCalls[index]?.dischargeTransportCallId}
                        />

                        <ShipmentDate
                          label='Actual Departure'
                          name={`transhipments.${index}.departure.actualDate`}
                          eventSubType={TransportEventSubTypeEnum.Depart}
                          eventType={CargoEventTypeEnum.Transport}
                          eventClassifier={CargoEventClassifierEnum.Actual}
                          transportCallId={transhipmentsTransportCalls[index]?.loadTransportCallId}
                        />

                        <ShipmentDate
                          label='Actual Arrival'
                          name={`transhipments.${index}.arrival.actualDate`}
                          eventSubType={TransportEventSubTypeEnum.Arrive}
                          eventType={CargoEventTypeEnum.Transport}
                          eventClassifier={CargoEventClassifierEnum.Actual}
                          transportCallId={transhipmentsTransportCalls[index]?.dischargeTransportCallId}
                        />

                        {isAir && (
                          <CargoOffLoadedPlacement>
                            <ShipmentDate
                              label='Cargo Off-Loaded'
                              name={`transhipments.${index}.discharged.actualDate`}
                              eventSubType={CargoEventSubTypeEnum.Discharge}
                              eventType={CargoEventTypeEnum.Cargo}
                              eventClassifier={CargoEventClassifierEnum.Actual}
                              transportCallId={transhipmentsTransportCalls[index]?.previousDischargeTransportCallId}
                            />
                          </CargoOffLoadedPlacement>
                        )}

                        {isOcean && (
                          <>
                            {index === fields.length - 1 ? (
                              <BerthedPlacement>
                                <ShipmentDate
                                  label='Actual Berthed'
                                  name={`transhipments.${index}.berth.actualDate`}
                                  eventSubType={TransportEventSubTypeEnum.Berth}
                                  eventType={CargoEventTypeEnum.Transport}
                                  eventClassifier={CargoEventClassifierEnum.Actual}
                                  transportCallId={transhipmentsTransportCalls[index]?.dischargeTransportCallId}
                                />
                              </BerthedPlacement>
                            ) : null}
                          </>
                        )}
                      </CDetailsCardContent2RowsLayout>
                    </CDetailsCard>
                  </CDetailsTimelineItem>
                </Badge.Ribbon>
              </Fragment>
            );
          })
        : null}
    </>
  );
};
export default TranshipmentsSection;
