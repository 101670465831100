import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { useCargoControllerApi } from '@services/ShipmentTrackerService/cargoControllerApi/useCargoControllerApi';
import { useState } from 'react';

export function useUpdateEquipmentKind() {
  const [isSaving, setIsSaving] = useState(false);
  const { carrierShipmentData, shipmentTrackerCargo, refetchAllData } = useShipmentDetailPageCargoData();
  const { reindexCargoByCargoId, patchCargo } = useCargoControllerApi();
  const cargoId = shipmentTrackerCargo?.id || '';
  return {
    isSaving,
    async updateEquipmentKind(equipmentKind: string) {
      if (carrierShipmentData?.carrierShipment?.id) {
        setIsSaving(true);
        await patchCargo(cargoId, {
          carrierShipmentId: carrierShipmentData?.carrierShipment?.id,
          container: {
            isoCode: equipmentKind,
          },
        });

        await reindexCargoByCargoId(cargoId);
        setTimeout(() => {
          refetchAllData();
          setIsSaving(false);
          document.querySelectorAll('.ant-select-focused').forEach((picker) => {
            picker.classList.remove('ant-select-focused');
          });
        }, 300);
      }
    },
  };
}
