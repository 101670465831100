import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { Box } from '@beacon-devops/components';
import useDebounce from '@utils/hooks/useDebouncedValue';
import { getAll } from '@beacon-devops/location-provider';
import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { LocationType } from '../../types';
import { getPortLocations } from '../../utils/common';
import { FormLabel } from '../form';
import { useUpdatePort } from './useUpdatePort';

interface ContainerFacilitySearchProps {
  label: string;
  name: string;
  type: LocationType;
  transportCallId: string;
}

export const PortSearch = ({ label, name, type, transportCallId }: ContainerFacilitySearchProps): JSX.Element => {
  const { carrierShipmentTransportCalls, carrierShipmentData } = useShipmentDetailPageCargoData();
  const transportCall = carrierShipmentTransportCalls.find((x) => x.id === transportCallId);
  const transportCallLocationId = transportCall?.locationId;
  const transportCallLocation = carrierShipmentData?.locations?.find((x) => x.id === transportCallLocationId);
  const [searchValue, setSearchValue] = useState('');
  const { isSaving, updatePortInTransportCalls } = useUpdatePort();

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const portsList = useMemo(() => {
    return getPortLocations(getAll(), type);
  }, [type]);

  const filteredPorts = useMemo(
    () =>
      portsList.reduce<
        Array<{
          id: string;
          name: string;
          zone?: string;
        }>
      >((acc, location) => {
        const iataCode = location.facility?.iataCode || '';
        const trimmedSearchValue = debouncedSearchValue.trim();
        const includesCode = location.unLocation?.code.includes(trimmedSearchValue.toUpperCase());
        const includesIataCode = iataCode.includes(trimmedSearchValue.toUpperCase());
        const includesName = location.name.toLowerCase().includes(trimmedSearchValue.toLowerCase());

        if (includesCode || includesName || includesIataCode) {
          return [
            ...acc,
            {
              id: location.id,
              name:
                type === LocationType.Airport ? `${location.name} ${iataCode ? `(${iataCode})` : ''}` : location.name,
              zone: location.zone,
            },
          ];
        }
        return acc;
      }, []),
    [portsList, debouncedSearchValue],
  );

  function handleSelect(value: string) {
    const selectedValue = filteredPorts.find((facility) => facility.id === value);
    if (selectedValue) {
      updatePortInTransportCalls(transportCallId, selectedValue.id);
    }
  }

  return (
    <Box width='100%'>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        id={name}
        style={{ width: '100%' }}
        loading={isSaving}
        onSearch={setSearchValue}
        disabled={isSaving}
        filterOption={false}
        showSearch
        placeholder={type === LocationType.Airport ? 'Please select a airport' : 'Please select a seaport'}
        onSelect={handleSelect}
        value={transportCallLocation?.name}
      >
        {filteredPorts?.map((x) => (
          <Select.Option value={x.id} key={x.id} title={x.name}>
            {x.name}
          </Select.Option>
        ))}
      </Select>
    </Box>
  );
};
