import React, { useMemo, useState } from 'react';
import { Input, Select } from 'antd';
import { Box } from '@beacon-devops/components';
import useDebounce from '@utils/hooks/useDebouncedValue';
import { useVesselList } from '@queries/containers';
import { FormLabel } from '../form';
import { useUpdateVessel } from './useUpdateVessel';
import { useVesselByDischargeTransportCallId } from './useVesselByDischargeTransportCallId';

interface Props {
  label: string;
  disabled?: boolean;
  loadTransportCallId: string;
  dischargeTransportCallId: string;
}

export const Vessel = ({
  label,
  disabled = false,
  loadTransportCallId,
  dischargeTransportCallId,
}: Props): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 250);
  const { data: vesselList = [], isLoading: isLoadingVesselList } = useVesselList();

  const filteredVessels = useMemo(
    () =>
      vesselList.filter((vessel) =>
        vessel.name ? vessel.name.includes(debouncedSearchValue.trim().toUpperCase()) : false,
      ),
    [vesselList, debouncedSearchValue],
  );
  const { vesselValue, vesselImoNumber } = useVesselByDischargeTransportCallId({
    dischargeTransportCallId,
  });
  const { updateVesselInTransportCalls, isSaving } = useUpdateVessel();

  const onVesselSelect = async (value: string) => {
    const splitValue = value.split('-');
    const vesselName = splitValue[0];
    const vesselImoNumber = splitValue[1];
    await updateVesselInTransportCalls(loadTransportCallId, dischargeTransportCallId, vesselImoNumber, vesselName);
  };

  return (
    <Box width='200px'>
      <FormLabel>{label}</FormLabel>
      <Select
        style={{ width: '100%' }}
        loading={isLoadingVesselList || isSaving}
        onSearch={setSearchValue}
        filterOption={false}
        showSearch
        disabled={disabled || isSaving}
        placeholder='Please select a vessel'
        onSelect={onVesselSelect}
        value={vesselValue}
      >
        {filteredVessels?.map((vessel) => (
          <Select.Option
            value={`${vessel.name}-${vessel.imo}`}
            key={`${vessel.name}-${vessel.imo}`}
            title={vessel.name}
          >
            {vessel.name}
          </Select.Option>
        ))}
      </Select>
      <Box mt={7}>
        <FormLabel>Vessel IMO</FormLabel>
        <Input disabled value={vesselImoNumber} />
      </Box>
    </Box>
  );
};
