import { useShipmentDetailPageCargoData } from '@features/shipmentDetail/hooks/useShipmentDetailPageData';
import { useCargoControllerApi } from '@services/ShipmentTrackerService/cargoControllerApi/useCargoControllerApi';
import { useCarrierShipmentApi } from '@services/ShipmentTrackerService/carrierShipmentApi/useCarrierShipmentApi';
import { useState } from 'react';

export function useUpdateCarrier() {
  const [isSaving, setIsSaving] = useState(false);
  const { updateCarrierShipment } = useCarrierShipmentApi();
  const { carrierShipmentData, shipmentTrackerCargo, refetchAllData } = useShipmentDetailPageCargoData();
  const { reindexCargoByCargoId } = useCargoControllerApi();
  const cargoId = shipmentTrackerCargo?.id || '';
  return {
    isSaving,
    async updateCarrier(carrierId: string) {
      if (carrierShipmentData?.carrierShipment?.id) {
        setIsSaving(true);
        await updateCarrierShipment(carrierShipmentData?.carrierShipment?.id, {
          carrierId,
          customerId: carrierShipmentData?.carrierShipment?.customerId,
        });

        await reindexCargoByCargoId(cargoId);
        setTimeout(() => {
          refetchAllData();
          setIsSaving(false);
          document.querySelectorAll('.ant-select-focused').forEach((picker) => {
            picker.classList.remove('ant-select-focused');
          });
        }, 300);
      }
    },
  };
}
