import React, { ReactElement, ReactText } from 'react';
import { Button, Input } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import _get from 'lodash/get';
import _lowerCase from 'lodash/lowerCase';
import _isEmpty from 'lodash/isEmpty';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { notEmpty } from '@utils/generalUtilities';
import { ColumnControlledValues } from '@beacon-types/Table';

type GetColumnTextSearchProps = {
  forwardedRef: Input | null;
  onSearch: (selectedKeys: ReactText[], confirm: () => void, dataIndex: string) => void;
  onReset: (clearFilters?: () => void) => void;
  searchedColumn: string;
  searchText: ReactText;
  columnControlValues?: Record<string, ColumnControlledValues>;
  dataIndex: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell: any;
  placeholder: string;
  disableHighlighter?: boolean;
};

export const StringTemplate = (fieldText: string): ReactElement => <span>{fieldText || '-'}</span>;

export const getColumnTextSearchProps: <T>(props: GetColumnTextSearchProps) => ColumnType<T> = ({
  dataIndex,
  onSearch,
  onReset,
  forwardedRef,
  searchedColumn,
  searchText,
  columnControlValues,
  renderCell,
  placeholder,
  disableHighlighter,
}) => ({
  filterDropdown: function FilterDropdown({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) {
    return (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          ref={(node) => {
            // eslint-disable-next-line no-param-reassign
            forwardedRef = node;
          }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => onSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => onSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined style={{ fontSize: '1rem' }} />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            onReset(clearFilters);
          }}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    );
  },
  filterIcon: function FilterIcon(filtered) {
    return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '1rem' }} />;
  },
  onFilter: (value = '', record) => {
    const recordValue = _get(record, dataIndex, null);
    return recordValue && value ? _lowerCase(recordValue).includes(_lowerCase(String(value))) : false;
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => forwardedRef && forwardedRef.select());
    }
  },
  render: (value, record) => {
    let highlightSearchedText = searchedColumn === dataIndex;
    let textToHighlight = value ? value.toString() : '';
    let filteredValue: string[] = [];

    if (columnControlValues && dataIndex) {
      const columnControlValue = columnControlValues[dataIndex];
      if (columnControlValue && columnControlValue.filteredValue) {
        filteredValue = columnControlValue.filteredValue as string[];
        highlightSearchedText = true;
      }
    }

    return !disableHighlighter && highlightSearchedText && notEmpty(textToHighlight)
      ? renderCell(
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={!_isEmpty(filteredValue) ? filteredValue : [searchText as string]}
            autoEscape
            textToHighlight={textToHighlight}
          />,
          record,
          filteredValue,
        )
      : renderCell(value, record, filteredValue);
  },
});
