import {
  Configuration,
  CargoEntityControllerApi,
  GenericResponse,
  ManagementControllerApi,
  Cargo,
  CarrierShipment,
  CarrierShipmentEntityControllerApi,
  CargoEvent,
  CargoEventEntityControllerApi,
  CargoEventSearchControllerApi,
  Location,
  LocationEntityControllerApi,
  TransportCall,
  TransportEventEntityControllerApi,
  TransportEvent,
  TransportSearchControllerApi,
  Transport,
  AggregatedDataControllerApi,
  CargoSearchControllerApi,
  CarrierShipmentEvents,
  TransportCallEntityControllerApi,
  TransportEntityControllerApi,
} from '@beacon-devops/shipment-tracker-client';
import { AxiosResponse } from 'axios';
import { MemoryCache } from 'memory-cache-node';
import { getAccessToken } from '../Auth';
import { shipmentTrackerBasePath } from './constants';

const accessToken = () => getAccessToken();
const apiConfiguration = new Configuration({ basePath: shipmentTrackerBasePath, accessToken });
const managementApi = new ManagementControllerApi(apiConfiguration);
const cargoApi = new CargoEntityControllerApi(apiConfiguration);
const cargoSearchApi = new CargoSearchControllerApi(apiConfiguration);
const cargoEventApi = new CargoEventEntityControllerApi(apiConfiguration);
const cargoEventSearchApi = new CargoEventSearchControllerApi(apiConfiguration);
const carrierShipmentApi = new CarrierShipmentEntityControllerApi(apiConfiguration);
const locationApi = new LocationEntityControllerApi(apiConfiguration);
const transportEventApi = new TransportEventEntityControllerApi(apiConfiguration);
const transportApi = new TransportEntityControllerApi(apiConfiguration);
const transportSearchApi = new TransportSearchControllerApi(apiConfiguration);
const transportCallApi = new TransportCallEntityControllerApi(apiConfiguration);
const aggregatedDataApi = new AggregatedDataControllerApi(apiConfiguration);

// Location cache config
const itemsExpirationCheckIntervalInSecs = 60;
const maxItemCount = 100;
const locationCacheTimeInSecs = 60;
const locationMemoryCache = new MemoryCache<string, Location>(itemsExpirationCheckIntervalInSecs, maxItemCount);

/**
 * Parses and returns an entity from shipment tracker with any extraneous information removed.
 *
 * @param jsonAsString Stringified version of Shipment Tracker entity response
 * @returns Object with any HATEOAS gubbins removed
 */
const parseShipmentTrackerResponse = (jsonAsString: string) => {
  let parsedEntity = JSON.parse(jsonAsString);
  // eslint-disable-next-line no-underscore-dangle
  delete parsedEntity._links;
  return parsedEntity;
};

export const purgeCarrierShipmentData = async (carrierShipmentId: string): Promise<AxiosResponse<GenericResponse>> => {
  const token = await accessToken();
  // @ts-expect-error -- Seems like there is an error with GenericResponse within the client itself
  return managementApi.purgeCarrierShipmentData(carrierShipmentId, { headers: { Authorization: `Bearer ${token}` } });
};

export const bulkPurgeCarrierShipmentData = async (
  carrierShipmentIds: string[],
): Promise<AxiosResponse<GenericResponse>> => {
  const token = await accessToken();
  return managementApi.bulkPurgeCarrierShipmentData(carrierShipmentIds, {
    headers: { Authorization: `Bearer ${token}` },
  }) as Promise<AxiosResponse<GenericResponse>>;
};

export const getCargoById = async (cargoId: string): Promise<Cargo> => {
  const token = await accessToken();
  return cargoApi
    .getItemResourceCargoGet(cargoId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};

export const getCargosByCarrierShipmentId = async (carrierShipmentId: string): Promise<Cargo[]> => {
  const token = await accessToken();
  return cargoSearchApi
    .findByCarrierShipmentId(carrierShipmentId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) =>
      // eslint-disable-next-line no-underscore-dangle
      parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data._embedded?.cargos)),
    );
};

export const getCargoEventById = async (cargoEventId: string): Promise<CargoEvent> => {
  const token = await accessToken();
  return cargoEventApi
    .getItemResourceCargoeventGet(cargoEventId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};

export const getCargoEventsForCargo = async (cargoId: string): Promise<CargoEvent[]> => {
  const token = await accessToken();
  return cargoEventSearchApi
    .findByCargoId1(cargoId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) =>
      // eslint-disable-next-line no-underscore-dangle
      parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data._embedded?.cargoEvents)),
    );
};

export const getCarrierShipmentById = async (carrierShipmentId: string): Promise<CarrierShipment> => {
  const token = await accessToken();
  return carrierShipmentApi
    .getItemResourceCarriershipmentGet(carrierShipmentId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};

/**
 * Fetches an individual location from Shipment Tracker. Responses will be cached for 60 seconds.
 */
export const getLocationById = async (locationId: string): Promise<Location> => {
  let locationFromCache = locationMemoryCache.retrieveItemValue(locationId);
  if (locationFromCache) {
    return locationFromCache;
  }
  const token = await accessToken();
  return locationApi
    .getItemResourceLocationGet(locationId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)))
    .then((location) => {
      // Store in memory cache
      locationMemoryCache.storeExpiringItem(locationId, location, locationCacheTimeInSecs);
      return location;
    });
};

export const getTransportEventById = async (transportEventId: string): Promise<TransportEvent> => {
  const token = await accessToken();
  return transportEventApi
    .getItemResourceTransporteventGet(transportEventId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};

export const getEventsByCargo = async (cargoId: string): Promise<CarrierShipmentEvents> => {
  const token = await accessToken();
  return aggregatedDataApi
    .findEventsForCargo(cargoId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};

export const findCarrierShipmentWithDependenciesByCargoId = async (cargoId: string) => {
  return aggregatedDataApi
    .findCarrierShipmentWithDependenciesByCargoId(cargoId)
    .then((entityModelPayload) => entityModelPayload.data);
};

export const getTransportsByCargo = async (cargoId: string): Promise<Transport[]> => {
  const token = await accessToken();
  return transportSearchApi
    .findByCargoId2(cargoId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) =>
      // eslint-disable-next-line no-underscore-dangle
      parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data._embedded?.transports)),
    );
};

export const getTransportById = async (transportId: string): Promise<Transport> => {
  const token = await accessToken();
  return transportApi
    .getItemResourceTransportGet(transportId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};

export const getTransportCallById = async (transportCallId: string): Promise<TransportCall> => {
  const token = await accessToken();
  return transportCallApi
    .getItemResourceTransportcallGet(transportCallId, { headers: { Authorization: `Bearer ${token}` } })
    .then((entityModelPayload) => parseShipmentTrackerResponse(JSON.stringify(entityModelPayload.data)));
};
