import { EquipmentKind, EventDateTime, SignalType } from '@beacon-devops/container-tracking-api-model-ts-client';
import { CargoStatusEnum } from '@beacon-devops/shipment-tracker-client';

export enum LocationType {
  Seaport = 'SEAPORT',
  Airport = 'AIRPORT',
}

export enum DateFields {
  ArrivalDate = 'arrivalDate',
  DepartureDate = 'departureDate',
  DischargeDate = 'dischargeDate',
  GateOutFullDate = 'gateOutDate',
  DepartureDateReal = 'departureDateReal',
  GateOutEmptyDate = 'gateOutEmptyDate',
  GateInEmptyDate = 'gateInEmptyDate',
  GateInFullDate = 'gateInFullDate',
  BerthDate = 'berthDate',
}

export type SearchField = {
  id: string;
  name: string;
};

export type TranshipmentFields = {
  port?: SearchField;
  flightNumber?: string;
  discharged: {
    actualDate?: EventDateTime;
  };
  loaded: {
    actualDate?: EventDateTime;
  };
  vessel?: SearchField;
  voyagerNumber?: string;
  departure: {
    actualDate?: EventDateTime;
    expectedDate?: EventDateTime;
  };
  arrival: {
    actualDate?: EventDateTime;
    expectedDate?: EventDateTime;
  };
  berth: {
    actualDate?: EventDateTime;
  };
};

export type SCVContainerFormValues = {
  correlationIds: {
    customerId: string;
    carrierShipmentId: string;
    cargoId: string;
  };
  identifier: string;
  status?: CargoStatusEnum;
  carrier?: SearchField & { mawbPrefix?: string };
  vessel?: SearchField;
  voyagerNumber?: string;
  mawb?: string;
  pieces?: string;
  weight?: string;
  flightNumber?: string;
  equipmentKind?: EquipmentKind;
  freightForwarderName?: string;
  masterBillOfLading?: string;
  departurePort?: SearchField;
  arrivalPort?: SearchField;
  bookingConfirmed: {
    actualDate?: EventDateTime;
  };
  receive: {
    actualDate?: EventDateTime;
  };
  gateOutEmpty: {
    actualDate?: EventDateTime;
  };
  gateInFull: {
    actualDate?: EventDateTime;
  };
  loaded: {
    actualDate?: EventDateTime;
  };
  discharged: {
    actualDate?: EventDateTime;
  };
  gateOutFull: {
    actualDate?: EventDateTime;
  };
  collect: {
    actualDate?: EventDateTime;
  };
  gateInEmpty: {
    actualDate?: EventDateTime;
  };
  departure: {
    actualDate?: EventDateTime;
    expectedDate?: EventDateTime;
  };
  arrival: {
    actualDate?: EventDateTime;
    expectedDate?: EventDateTime;
  };
  berth: {
    actualDate?: EventDateTime;
  };
  transhipments?: TranshipmentFields[];
};

export type ContainerEventData = {
  eventID: string | null;
  transportCallID: string;
  locationID: string;
};

export type TransportDates = {
  departure: { actual?: ContainerEventData; expected?: ContainerEventData };
  arrival: { actual?: ContainerEventData; expected?: ContainerEventData };
  berth: { actual?: ContainerEventData };
  loaded: { actual?: ContainerEventData };
  discharged: { actual?: ContainerEventData };
};

export interface SCVContainerEventData extends TransportDates {
  gateOutEmpty: { actual?: ContainerEventData };
  gateInFull: { actual?: ContainerEventData };
  loaded: { actual?: ContainerEventData };
  bookingConfirmed: { actual?: ContainerEventData };
  receive: { actual?: ContainerEventData };
  discharged: { actual?: ContainerEventData };
  gateOutFull: { actual?: ContainerEventData };
  collect: { actual?: ContainerEventData };
  gateInEmpty: { actual?: ContainerEventData };
  transhipments: Array<TransportDates>;
}

export type CarrierDropdownEntry = {
  value: string;
  label: string;
};

export type DisabledDateRangesParams = {
  startDate?: string;
  endDate?: string;
};

export type GqlError = {
  message: string;
  locations: Array<unknown>;
  path: Array<string>;
  extensions?: { [key: string]: string };
};

export type GqlErrorResponse = {
  errors: GqlError[];
};

export type ManualCTATrackingMutationVariables = {
  signal: SignalType;
};

export interface CargoPageRouteParams {
  customerId: string;
  cargoId: string;
}
