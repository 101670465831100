import { PatchCargoDTO, Mode } from '@beacon-devops/container-tracking-api-model-ts-client';

import { SCVContainerFormValues } from '../types';

export const handleCargoOceanDelete = (cargoFormData: SCVContainerFormValues): PatchCargoDTO => {
  let patchCargoDTO: PatchCargoDTO = {
    correlationIds: cargoFormData.correlationIds,
    identifier: cargoFormData.identifier,
    mode: Mode.Ocean,
    carrierShipments: [{ delete: true }],
  };
  return patchCargoDTO;
};

export const handleCargoAirDelete = (cargoFormData: SCVContainerFormValues): PatchCargoDTO => {
  let patchCargoDTO: PatchCargoDTO = {
    correlationIds: cargoFormData.correlationIds,
    identifier: cargoFormData.mawb,
    mode: Mode.Air,
    carrierShipments: [{ delete: true }],
  };
  return patchCargoDTO;
};
