import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TrackingActiveDTO } from '@beacon-devops/tracking-info-client-ts';
import {
  SignalType,
  PatchCargoDTO,
  Vessel,
  CargoTrackingStatus,
  Mode as CTAMode,
} from '@beacon-devops/container-tracking-api-model-ts-client';
import TrackingInfoService from '@services/trackingInfoService';
import { ContainerTrackingService } from '@services/ContainerTrackingService';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { ManualCTATrackingMutationVariables } from '@features/shipmentDetail/types';

interface SetContainerTrackingMutationVariables {
  enabled: boolean;
}

export const useSetContainerTrackingMutation = (containerId: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, SetContainerTrackingMutationVariables>(
    ({ enabled }) => TrackingInfoService.setTrackingActive(containerId, enabled),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData(['container-tracking-active', containerId], { isTrackingActive: variables.enabled });
      },
    },
  );
};

export const useManualCTATrackingMutation = (identifier: string, mode = Mode.Ocean) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, ManualCTATrackingMutationVariables>(
    async ({ signal }) => {
      if (signal === SignalType.Start) return ContainerTrackingService.resumeUpdatesById(identifier, mode as CTAMode);

      return ContainerTrackingService.pauseUpdatesById(identifier, mode as CTAMode);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cargo-tracking-active']);
      },
    },
  );
};

export const useIsCTACargoTrackingActive = (identifier: string, mode: Mode) =>
  useQuery<CargoTrackingStatus, AxiosError>(
    ['cargo-tracking-active', identifier],
    () => ContainerTrackingService.getCargoTrackingStatus(identifier, mode as CTAMode),
    {
      staleTime: Infinity,
      retry: false,
    },
  );

export const useIsContainerTrackingActiveQuery = (containerId: string) =>
  useQuery<TrackingActiveDTO, AxiosError>(
    ['container-tracking-active', containerId],
    () => TrackingInfoService.isTrackingActive(containerId),
    {
      staleTime: Infinity,
      retry: false,
    },
  );

export const useVesselList = () =>
  useQuery<Array<Vessel>, AxiosError>(['tracking-vessels'], async () => ContainerTrackingService.getVessels(), {
    staleTime: 30 * 1000,
    retry: false,
  });

interface ContainerUpdateMutationVariables {
  patchCargoDTO: PatchCargoDTO;
}
export const usePatchCargoDataMutation = (carrierShipmentId: string, customerId: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, ContainerUpdateMutationVariables>(
    ({ patchCargoDTO }) => ContainerTrackingService.patchCargoData(patchCargoDTO),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['containers-gql', carrierShipmentId, customerId]);
      },
    },
  );
};

interface CargoDeleteMutationVariables {
  deleteCargoDTO: PatchCargoDTO;
}

export const useDeleteCargoMutation = (carrierShipmentId: string, customerId: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, CargoDeleteMutationVariables>(
    ({ deleteCargoDTO }) => ContainerTrackingService.patchCargoData(deleteCargoDTO),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['containers-gql', carrierShipmentId, customerId]);
      },
    },
  );
};
