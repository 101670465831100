import { Cargo, Mode } from '@beacon-devops/graphql-typescript-client';
import moment from 'moment';
import { CargoStatusEnum } from '@beacon-devops/shipment-tracker-client';
import { SCVContainerFormValues, TranshipmentFields } from '../types';

type ValidationKey = 'mawbValid' | 'statusValid';

type CargoValidation = {
  mawbValid: boolean;
  statusValid: boolean;
};

type CargoValidationParams = {
  mode?: Mode;
  originalCargo: Cargo;
  formData: SCVContainerFormValues;
};

type ValidatorParams = {
  cargo: Cargo;
  formData: SCVContainerFormValues;
};

interface ValidationRule {
  validator: (params: ValidatorParams) => boolean;
  errorMessage: string;
}

export type ValidationRange = {
  from: moment.Moment | null;
  to: moment.Moment | null;
};

type TranshipmentValidationRanges = {
  loaded: {
    actualDate: ValidationRange;
  };
  departure: {
    actualDate: ValidationRange;
    expectedDate: ValidationRange;
  };
  arrival: {
    actualDate: ValidationRange;
    expectedDate: ValidationRange;
  };
  berth: {
    actualDate: ValidationRange;
  };
  discharged: {
    actualDate: ValidationRange;
  };
};

export type EventValidationRanges = {
  bookingConfirmed: {
    actualDate: ValidationRange;
  };
  receive: {
    actualDate: ValidationRange;
  };
  loaded: {
    actualDate: ValidationRange;
  };
  departure: {
    actualDate: ValidationRange;
  };
  arrival: {
    actualDate: ValidationRange;
  };
  discharged: {
    actualDate: ValidationRange;
  };
  transhipments?: TranshipmentValidationRanges[];
};

export const validationRules: {
  [key in ValidationKey]: ValidationRule;
} = {
  mawbValid: {
    validator: ({ formData }) => {
      const airlineMawbPrefix: string = formData.carrier?.mawbPrefix || '';
      const mawb: string = formData.mawb || '';
      return mawb.startsWith(airlineMawbPrefix);
    },
    errorMessage: 'MAWB prefix is not valid for this airline',
  },
  statusValid: {
    validator: ({ formData }) => {
      // 1. UNSPECIFIED (PENDING) - Default, no dates populated
      // 2. CONFIRMED - Booking confirmed populated
      // 3. AWAITING LOADING - Cargo received populated
      // 4. LOADED - Loaded @ PoL populated
      // 5. IN TRANSIT - Actual departure from PoL populated
      // 6. ARRIVED - Actual arrival @ PoD populated
      // 7. OFFLOADED - Cargo Off-loaded @ PoD populated
      // 8. COLLECTED - Cargo collected populated
      switch (formData.status) {
        case CargoStatusEnum.Collected:
          return !!formData.collect.actualDate?.date || !!formData.collect.actualDate?.timestamp;
        case CargoStatusEnum.GatedOutFull:
          return !!formData.gateOutFull.actualDate?.date || !!formData.gateOutFull.actualDate?.timestamp;
        case CargoStatusEnum.GatedInFull:
          return !!formData.gateInFull.actualDate?.date || !!formData.gateInFull.actualDate?.timestamp;
        case CargoStatusEnum.InTransit:
          return !!formData.departure.actualDate?.date || !!formData.departure.actualDate?.timestamp;
        case CargoStatusEnum.LoadedAtOrigin:
          return !!formData.loaded.actualDate?.date || !!formData.loaded.actualDate?.timestamp;
        case CargoStatusEnum.Received:
          return !!formData.receive.actualDate?.date || !!formData.receive.actualDate?.timestamp;
        case CargoStatusEnum.Confirmed:
          return !!formData.bookingConfirmed.actualDate?.date || !!formData.bookingConfirmed.actualDate?.timestamp;
        default:
          return true;
      }
    },
    errorMessage: 'Status is not valid for the event dates currently populated',
  },
};

export const handleValidation = ({ originalCargo, formData }: CargoValidationParams): CargoValidation => {
  const params: ValidatorParams = {
    cargo: originalCargo,
    formData,
  };
  return Object.keys(validationRules).reduce((acc, key) => {
    const { validator } = validationRules[key as ValidationKey];
    return {
      ...acc,
      [key]: validator(params),
    };
  }, {} as CargoValidation);
};

const getTranshipmentValidEventRanges = (transhipment: TranshipmentFields): TranshipmentValidationRanges => {
  return {
    loaded: {
      actualDate: {
        from: null,
        to: moment(transhipment.departure.actualDate?.timestamp),
      },
    },
    departure: {
      actualDate: {
        from: moment(transhipment.loaded.actualDate?.timestamp),
        to: moment(transhipment.arrival.actualDate?.timestamp),
      },
      expectedDate: {
        from: moment(transhipment.loaded.actualDate?.timestamp),
        to: moment(transhipment.arrival.expectedDate?.timestamp),
      },
    },
    arrival: {
      actualDate: {
        from: moment(transhipment.discharged.actualDate?.timestamp),
        to: moment(transhipment.departure.actualDate?.timestamp),
      },
      expectedDate: {
        from: moment(transhipment.discharged.actualDate?.timestamp),
        to: moment(transhipment.departure.actualDate?.timestamp),
      },
    },
    berth: {
      actualDate: {
        from: moment(transhipment.discharged.actualDate?.timestamp),
        to: moment(transhipment.arrival.actualDate?.timestamp),
      },
    },
    discharged: {
      actualDate: {
        from: moment(transhipment.arrival.actualDate?.timestamp),
        to: null,
      },
    },
  };
};

export const getValidEventRanges = ({ formData }: { formData: SCVContainerFormValues }): EventValidationRanges => {
  return {
    bookingConfirmed: {
      actualDate: {
        from: null,
        to: null,
      },
    },
    receive: {
      actualDate: {
        from: moment(formData.bookingConfirmed.actualDate?.timestamp),
        to: moment(formData.loaded.actualDate?.timestamp),
      },
    },
    loaded: {
      actualDate: {
        from: moment(formData.receive.actualDate?.timestamp),
        to: moment(formData.departure.actualDate?.timestamp),
      },
    },
    departure: {
      actualDate: {
        from: moment(formData.loaded.actualDate?.timestamp),
        to: moment(formData.arrival.actualDate?.timestamp),
      },
    },
    arrival: {
      actualDate: {
        from: moment(formData.departure.actualDate?.timestamp),
        to: moment(formData.discharged.actualDate?.timestamp),
      },
    },
    discharged: {
      actualDate: {
        from: moment(formData.arrival.actualDate?.timestamp),
        to: null,
      },
    },
    transhipments: formData.transhipments?.map(getTranshipmentValidEventRanges),
  };
};
