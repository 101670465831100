import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { CargoEvent, EventDateTime, Location } from '@beacon-devops/shipment-tracker-client';
// Local imports
import { getLocationById } from '@services/ShipmentTrackerService/ShipmentTrackerService';
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import {
  AddInspectionEntity,
  buildInspectLink,
  buildLocationLink,
  renderEntityItem,
  renderEntityItemDateTime,
  ScvEntityType,
} from './ScvCommanderEntityUtils';

interface ScvCommanderEntityCargoEventProps {
  entityId: string;
  entityData: CargoEvent;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Cargo Event given some data from Shipment Tracker.
 */
export const ScvCommanderEntityCargoEvent = (props: ScvCommanderEntityCargoEventProps): JSX.Element => {
  const cargoEvent = props.entityData;

  const [eventLocation, setEventLocation] = useState<Location>();

  // Fetch the location related to the event
  useEffect(() => {
    if (cargoEvent.locationId) {
      getLocationById(cargoEvent.locationId).then((location: Location) => setEventLocation(location));
    }
  }, [cargoEvent.locationId]);

  /**
   * Renders the Cargo Event card plus any extra information for this entity
   * @param cargoEvent CargoEvent entity from Shipment Tracker
   */
  function render(cargoEvent: CargoEvent): JSX.Element {
    function renderEventDateTime(eventDateTime: EventDateTime): string {
      return eventDateTime ? `${eventDateTime.timestamp} (${eventDateTime.zone})` : '';
    }

    return (
      <>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Cargo Event</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', cargoEvent.createdAt)}
          {renderEntityItemDateTime('Last Updated At', cargoEvent.updatedAt)}
          {renderEntityItem('Type', cargoEvent.subType)}
          {renderEntityItem('Classifier', cargoEvent.classifier)}
          {renderEntityItem('Source Type', cargoEvent.source?.sourceType)}
          {renderEntityItem('Source Identifier', cargoEvent.source?.identifier)}
          {renderEntityItem('Date & Time', renderEventDateTime(cargoEvent.eventDateTime))}
          {renderEntityItem(
            'Transport Call',
            buildInspectLink(
              ScvEntityType.transportCall,
              cargoEvent.transportCallId,
              props.addInspectionEntityFunc,
              true,
            ),
          )}
          {renderEntityItem(
            'Location',
            buildLocationLink(eventLocation, cargoEvent.transportCallId, props.addInspectionEntityFunc),
          )}
        </Card>
      </>
    );
  }

  return <div>{cargoEvent ? render(cargoEvent) : '...loading'}</div>;
};
