import {
  IdArray,
  Configuration,
  QuarantineApi,
  ReportDataApi,
} from '@beacon-devops/shipment-data-quality-engine-ts-client';
import { GATEWAY_SERVICE } from '@constants/index';
import { getAccessToken } from './Auth';

const accessToken = () => getAccessToken();

const basePath = `${GATEWAY_SERVICE}/shipment-data-quality-engine`;

const reportDataApi = new ReportDataApi(new Configuration({ basePath, accessToken }));
const quarantineApi = new QuarantineApi(new Configuration({ basePath, accessToken }));

export interface RequestReportDataArgs {
  page: number;
  size: number;
}

const requestQuarantinedReportData = async ({ page = 1, size = 20 }: RequestReportDataArgs) => {
  const response = await reportDataApi.requestQuarantinedReportData(page, size);
  response.data.reports?.forEach(function setMode(report) {
    if (report.violatedRuleCodes) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report.mode = report.violatedRuleCodes[0].startsWith('S') ? 'ocean' : 'air';
    }
  });
  return response.data;
};

const deleteQuarantinedReportData = async (dataId: string) => {
  const idArray: IdArray = {
    dataIds: [dataId],
  };
  const response = await quarantineApi.disposeQuarantinedItems(idArray);
  return response.data;
};

const releaseQuarantinedReportData = async (dataId: string) => {
  const idArray: IdArray = {
    dataIds: [dataId],
  };
  const response = await quarantineApi.releaseQuarantinedItems(idArray);
  return response.data;
};

const requestReportDetails = async (dataId: string) => {
  const response = await reportDataApi.requestReportDataById(dataId);
  return response.data;
};

export const ShipmentDataQualityEngineService = {
  requestQuarantinedReportData,
  deleteQuarantinedReportData,
  releaseQuarantinedReportData,
  requestReportDetails,
};
